<script setup>
import CountdownTimer from '@/Components/CountdownTimer.vue';
import {format, parseISO} from 'date-fns'
import { uk } from 'date-fns/locale'
import noAvatar from "@/../images/tmp/ProductOwnerProfile/avatar.png";
import {useDateFormatter} from "@/composables/useDateFormatter.js";

const props = defineProps({
  workShop: Object
})

const { formatDate } = useDateFormatter();

</script>

<template>
  <div class="video-info">
<!--    <pre>{{workShop}}</pre>-->
    <b-img
        v-if="workShop && workShop.image"
        :src="workShop.image"
        title="title"
        class="ms-2 workshop-image" alt="title"
    ></b-img>
    <div class="video-info-speaker mt-4">
      <b-avatar
          v-if="workShop && workShop.speakers.data && workShop.speakers.data && workShop.speakers.data.length > 0"
          variant="info"
          :src="workShop.speakers.data[0].image ? workShop.speakers.data[0].image : noAvatar"
      ></b-avatar>
      <div class="d-flex flex-column align-items-start ms-2 h-100">

        <div
            v-if="workShop && workShop.speakers.data && workShop.speakers.data && workShop.speakers.data.length > 0"
            class="video-info-speaker__label p-1"
        >
            {{workShop.speakers.data[0].label}}
        </div>
        <div
            v-if="workShop && workShop.speakers.data && workShop.speakers.data && workShop.speakers.data.length > 0"
            class="video-info-speaker__name p-1"
        >
            {{workShop.speakers.data[0].name}}
        </div>
      </div>
    </div>
    <div class="video-info-title my-4">
      {{workShop.title}}
    </div>
    <div class="video-info-description">
      <span>Що ви дізнаєтесь на воркшопі?</span>
      <div>{{ workShop.description }}</div>
      <div class="description-fade"></div>
    </div>
    <div v-if="workShop.type !== 'MASTERCLASS'" class="video-info-dates">
      <div>{{ formatDate(workShop.config.date, 'EEEE, d MMMM, HH:mm') }}</div>
      <div class="ms-3">Тривалість: {{workShop.duration}} год</div>
    </div>
    <CountdownTimer v-if="workShop.type !== 'MASTERCLASS'" :date-time="workShop.originalTimeStamp" class="mt-4"/>
  </div>
</template>

<style lang="scss" scoped>
.video-info {
  color: var(--white, #FFF);
  background: #0F241A;
  width: auto;
  height: 100%;
  padding: 80px 0 0 120px;

  .video-info-speaker {
    display: flex;

    .video-info-speaker__label {
      color: var(--white, #FFF);
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 10px */
      opacity: 0.5;
    }

    .video-info-speaker__name {
      color: var(--white, #FFF);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 14px */
    }
  }

  .video-info-title {
    color: var(--white, #FFF);
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
  }

  .video-info-description {
    width: 65%;
    padding-bottom: 20px;
    border-bottom: 1px solid #FFF;
    margin-bottom: 20px;
    position: relative;

    span {
      color: var(--white, #FFF);
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      opacity: 0.5;
    }

    div {
      color: var(--white, #FFF);
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;
    }

    .description-fade {
      position: absolute;
      bottom: 15px;
      height: 37px;
      background: linear-gradient(0deg, #0F241A 0%, rgba(15, 36, 26, 0.00) 100%);
      width: 100%;
    }
  }

  .video-info-dates {
    display: flex;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%; /* 14px */
  }
  .workshop-image {
    max-height: 35vh;
    border-radius: 12px;
  }
}
</style>
