<script setup>
import { computed, ref } from "vue";
import VideoUploadModal from "@/Components/modals/VideoUploadModal.vue";
import { router } from "@inertiajs/vue3";
import VideoPlayer from "@/Components/VideoPlayer.vue";
import addVideo from "../../../../images/svg/lesson-icons/add-video.svg";
import InputGroup from "@/Components/InputGroup.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";

const props = defineProps({
    modelValue: Object,
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    "update:modelValue",
    "videoUploadComplete",
    "remove-lesson-file",
]);
const video = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const removeVideo = (id) => {
    console.log("removeVideo", id);
    console.log(router.page.url);
    router.visit(route("courses.remove_lesson_file"), {
        method: "post",
        data: { redirect: router.page.url, lessonTaskId: props.modelValue.id },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (reponse) => {
            props.form.course.contentItems = reponse.props.course.contentItems;
        },
    });
};

const collapsePlayer = ref(false);

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    let formattedTime = "";

    if (hours > 0) {
        formattedTime += `${hours} год. `;
    }

    if (minutes > 0) {
        formattedTime += `${minutes} хв. `;
    }

    if (remainingSeconds > 0 || formattedTime === "") {
        formattedTime += `${remainingSeconds} сек.  `;
    }

    return formattedTime.trim();
}

const removeModal = ref(false);
</script>

<template>
    <div class="video-lesson">
        <div class="video-lesson__info">
            <div class="video-lesson__preview">
                <div class="video-lesson__label">Превью відео:</div>
                <b-img
                    :src="video.video_preview"
                    fluid
                    alt="Video lesson preview"
                    class="video-lesson__thumbnail"
                ></b-img>
            </div>
            <div class="video-lesson__info-content">
                <div class="video-lesson__label">Детальна інформація:</div>
                <div class="video-lesson__label">
                    <span v-if="video.video_length && video.video_length > 0"
                        >Тривалість:
                        <strong>{{
                            formatTime(video.video_length)
                        }}</strong></span
                    >
                    Розмір: <strong>{{ video.video.size }}</strong
                    >.
                </div>
                <div class="video-lesson__label">Додайте опис до відео:</div>
                <InputGroup
                    element="textarea"
                    v-model="video.title"
                    :rows="6"
                    :max-rows="10"
                    :maxLength="255"
                    :disabled="disabled"
                    size="lg"
                    class="video-lesson__description"
                />
            </div>
        </div>
        <div
            class="video-lesson__label cursor-pointer m-0"
            @click="collapsePlayer = !collapsePlayer"
        >
            Відео програвач
            <i v-if="collapsePlayer" class="bi bi-caret-up-fill"></i>
            <i v-else class="bi bi-caret-down-fill"></i>
        </div>
        <b-collapse v-model="collapsePlayer" class="w-100">
            <div
                v-if="!video.video.status || video.video.status !== 'Finished'"
                class="video-status"
            >
                <img
                    :src="video.video_preview"
                    title=""
                    alt=""
                    class="video-status__preview"
                />
                <div class="video-status__loader">
                    <div>
                        <b-spinner variant="primary"></b-spinner>
                    </div>
                    <div>
                        {{
                            video.video.status
                                ? $t(video.video.status)
                                : "Обробка"
                        }}
                    </div>
                </div>
            </div>
            <VideoPlayer v-else :video-src="video.video.path" />
            <!--          <VideoPlayer-->
            <!--              v-else-->
            <!--              :previewImageLink="video.video_preview"-->
            <!--              :link="video.video.path"-->
            <!--              class="video-lesson__player"-->
            <!--              type="default"-->
            <!--              :progress="30"-->
            <!--              :isMuted="false"-->
            <!--              :isControls="true"-->
            <!--          >-->
            <!--          </VideoPlayer>-->
        </b-collapse>
        <div class="d-flex flex-row-reverse">
            <b-button
                variant="primary"
                pill
                :disabled="disabled"
                @click="removeModal = !removeModal"
            >
                Видалити відео <i class="bi bi-trash remove-btn"></i>
            </b-button>
        </div>
        <ConfirmModal
            v-model="removeModal"
            title="Видалити відео"
            decline-label="Скасувати"
            accept-label="Так, видалити"
            @accept-btn="() => emit('remove-lesson-file', video)"
            @decline-btn="() => console.log('decline')"
        >
            <template v-slot:description>
                Видаливши відео, його не можна буде повернути. Видалити відео?
            </template>
        </ConfirmModal>
    </div>
</template>

<style scoped>
.video-lesson {
    font-family: e-Ukraine;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--tb-card-bg);
    border-radius: 8px;
    padding: 1.2rem;
    margin-bottom: 16px;
    .video-lesson__label {
        margin-bottom: 8px;
        color: #1e1e1e;
    }
    .video-lesson__info {
        width: 100%;
        display: flex;
        gap: 16px;
        .video-lesson__preview {
            width: 35%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .video-lesson__thumbnail {
                border-radius: 8px;
            }
        }
        .video-lesson__info-content {
            display: flex;
            flex-direction: column;
            flex: 1;
            .video-lesson__description {
                flex: 1;
                margin: 0 !important;
            }
        }
    }
    .video-status {
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        background: #f7f7f8;
        min-height: 150px;
        width: 100%;
        .video-status__preview {
            width: 100%;
            opacity: 25%;
        }
        .video-status__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            padding: 16px;
            border-radius: 8px;
            background-color: #fff;
        }
    }
    .video-lesson__player {
        width: 100%;
    }
}
/*
.video-lesson {
  display: flex;
  margin-bottom: 10px;
  gap: 20px;

  .video-lesson__thumbnail {
    width: 25%;
  }

  .video-lesson__description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 75%;

    .description-lable {
      font-family: e-Ukraine;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
  }

  .remove-btn {
    color: #C2C2C2;
    font-size: 16px;
    cursor: pointer;
    &:hover {
        color: black;
    }
  }
}
.course-lesson__video {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 16px 0;
    .video-status {
        width: 50%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        background: #F7F7F8;
        min-height: 150px;
        .video-status__preview {
            width: 100%;
            opacity: 25%;
        }
        .video-status__loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            padding: 16px;
            border-radius: 8px;
            background-color: #fff;
        }
    }
    .course-lesson__video--player {
        width: 50%;
        border-radius: 8px;
    }
} */
</style>
