<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import InputGroup from "@/Components/InputGroup.vue";
import { computed, ref } from "vue";
import { useClipboard } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import SpeakerField from "@/Components/form/SpeakerField.vue";
import MyTeamModal from "@/Components/modals/MyTeamModal.vue";
import FileUploader from "@/Components/form/FileUploader.vue";
import PublicLink from "@/Components/form/PublicLink.vue";
import { helpers, maxLength, required, url } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import DateTimeSelector from "@/Components/form/DateTimeSelector.vue";
import WebinarButtonsCard from "@/Components/WebinarButtons/WebinarButtonsCard.vue";
import { format, parseISO } from "date-fns";
import { uk } from "date-fns/locale";
import WorkshopTeamComponent from "@/Pages/Workshops/Partials/WorkshopTeamComponent.vue";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const { t } = useI18n();

const props = defineProps({
    form: Object,
    workshopTypes: Object,
    team: Object,
    teamPresenter: Object,
    teamModerator: Object,
    registerLink: "",
    roles: "",
    link: "",
    workshops: Array,
    initialBaseWorkShopId: String,
    parentWebinar: Object,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const isExpired = computed(
    () => props.form?.workShop?.config?.workShopStatus === "EXPIRED"
);
const isFinished = computed(
    () => props.form?.workShop?.config?.workShopStatus === "FINISHED"
);

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles) || isFinished.value || isExpired.value;

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const workshopsNames = computed(() => {
    return props.workshops && props.workshops.length > 0
        ? props.workshops.map((item) => {
              return {
                  value: item.id,
                  text: `${item.title} (${format(
                      parseISO(item.date),
                      "d MMMM yyyy, HH:mm",
                      { locale: uk }
                  )})`,
              };
          })
        : [];
});

// const initialBaseWorkShopId = ref(props.form.workShop?.baseWorkShopId);

const changeBaseWebinarConfirmModal = ref(false);

const openChangeBaseWebinarConfirmModal = (baseWorkShopId) => {
    changeBaseWebinarConfirmModal.value = true;
};

const getParentWebinarInfo = (id) => {
    emit("get-parent-info", id);
};

const onSubmit = () => {
    v$.value["title"].$touch();
    if (!v$.value.$invalid) {
        emit("save");
    }
};

const onReset = () => {
    form.reset();
};

const { text, copy, copied, isSupported } = useClipboard({
    legacy: true,
});

const emit = defineEmits(["save", "get-parent-info"]);

const rules = {
    title: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        maxLength: helpers.withMessage("Не більше 90 символів", maxLength(90)),
    },
    completionRedirectUrl: {
        url: helpers.withMessage("Повино бути URL", url),
        maxLength: helpers.withMessage(
            "Не більше 255 символів",
            maxLength(255)
        ),
    },
};
const v$ = useVuelidate(rules, props.form.workShop, { $lazy: true });
console.log(props.form);
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="6">
                <b-form>
                    <FileUploader
                        :form="form"
                        :imagePreview="form.workShop.image"
                        :disabled="isReadOnly"
                        customTitle=" або перетягніть прев’ю"
                        customSubTitle="Рекомендований формат 16:9, JPEG, PNG"
                    />
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <SelectInputGroup
                                label="Статус"
                                v-model="form.workShop.status"
                                :options="statuses"
                                :disabled="isReadOnly"
                                size="lg"
                                class="w-100"
                            />
                            <PublicLink :public-link="registerLink" />
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">Основні дані</div>

                            <InputGroup
                                label="Назва автовебінару"
                                v-model="form.workShop.title"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                                :max-length="90"
                                :disabled="isReadOnly"
                                :is-error="v$.title?.$errors[0]?.$message"
                                :error-message="v$.title?.$errors[0]?.$message"
                                @blur="v$.title.$touch()"
                            />

                            <InputGroup
                                element="textarea"
                                label="Анонс до автовебінару"
                                v-model="form.workShop.description"
                                :options="statuses"
                                :rows="3"
                                :max-rows="10"
                                :max-length="255"
                                :disabled="isReadOnly"
                                size="lg"
                                class="mt-3 w-100"
                            />
                            <div
                                class="d-flex align-items-center justify-content-between w-100 gap-2"
                            >
                                <SelectInputGroup
                                    label="Оберіть вебінар для показу"
                                    v-model="form.workShop.baseWorkShopId"
                                    :options="workshopsNames"
                                    :disabled="isReadOnly"
                                    size="lg"
                                    class="w-100 mt-3"
                                    @change="
                                        () => {
                                            openChangeBaseWebinarConfirmModal();
                                        }
                                    "
                                />
                                <i
                                    id="base-webinar-select"
                                    class="bi bi-info-circle pt-3"
                                ></i>
                            </div>
                            <b-tooltip
                                class="text-left"
                                target="base-webinar-select"
                                custom-class="custom-tooltip"
                                offset="5"
                                triggers="hover"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                <p>
                                    Оберіть з переліку вебінар, який буде
                                    використано для проведення цього
                                    автовебінару.
                                </p>
                                <p>
                                    <span class="fw-bold">
                                        Якщо перелік порожній,</span
                                    >
                                    <span
                                        class="text-primary text-decoration-underline"
                                    >
                                        створіть
                                    </span>
                                    та проведіть свій перший вебінар, і він
                                    автоматично зʼявиться в цьому переліку.
                                </p>
                            </b-tooltip>
                        </b-card-body>
                    </b-card>
                    <WebinarButtonsCard
                        v-model="form.webinarButtons"
                        :form="form"
                    />
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start gap-3 bg-light p-3"
                        >
                            <div class="card__title">
                                Вебінарні конфігурації
                            </div>
                            <div
                                v-if="!!form.workShop.baseWorkShopId"
                                class="border-black border-bottom"
                            >
                                <b-form-checkbox
                                    v-model="
                                        form.workShop.config
                                            .is_base_config_setting_inherited
                                    "
                                    :unchecked-value="false"
                                    :disabled="isReadOnly"
                                >
                                    Наслідувати конфігурації
                                </b-form-checkbox>
                            </div>
                            <div
                                v-if="
                                    !form.workShop.config
                                        .is_base_config_setting_inherited
                                "
                            >
                                <b-form-checkbox
                                    v-model="form.workShop.config.is_chat_write"
                                    :unchecked-value="false"
                                    :disabled="isReadOnly"
                                >
                                    Увімкнути чат
                                    <i
                                        id="configuration-chat"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-form-checkbox
                                    v-model="
                                        form.workShop.config.moderate_access
                                    "
                                    :unchecked-value="false"
                                    :disabled="isReadOnly"
                                    >Підтверджувати користувачів
                                    <i
                                        id="configuration-confirm-user"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-form-checkbox
                                    v-model="
                                        form.workShop.config.link_message_status
                                    "
                                    :unchecked-value="false"
                                    :disabled="isReadOnly"
                                >
                                    Дозволити посилання в чаті<i
                                        id="configuration-link"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-form-checkbox
                                    v-model="
                                        form.workShop.config.moderate_message
                                    "
                                    :unchecked-value="false"
                                    :disabled="isReadOnly"
                                >
                                    Модерувати чат
                                    <i
                                        id="configuration-moderation-chat"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-chat"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Дозволяє учасникам писати повідомлення в чат
                                    під час вебінару.
                                </b-tooltip>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-confirm-user"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Ви будете підтверджувати кожного учасника,
                                    перш ніж він увійде на вебінар.
                                </b-tooltip>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-link"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Учасники зможуть ділитися посиланнями в чаті
                                    під час вебінару.
                                </b-tooltip>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-moderation-chat"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Всі повідомлення учасників перед публікацією
                                    буде схвалювати модератор.
                                </b-tooltip>
                            </div>
                            <div
                                v-else-if="
                                    form.workShop.config
                                        .is_base_config_setting_inherited &&
                                    !!parentWebinar
                                "
                            >
                                <b-form-checkbox
                                    :disabled="true"
                                    v-model="parentWebinar.config.is_chat_write"
                                    :unchecked-value="false"
                                >
                                    Увімкнути чат
                                    <i
                                        id="configuration-chat"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :disabled="true"
                                    v-model="
                                        parentWebinar.config.moderate_access
                                    "
                                    :unchecked-value="false"
                                    >Підтверджувати користувачів
                                    <i
                                        id="configuration-confirm-user"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :disabled="true"
                                    v-model="
                                        parentWebinar.config.link_message_status
                                    "
                                    :unchecked-value="false"
                                >
                                    Дозволити посилання в чаті
                                    <i
                                        id="configuration-link"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>
                                <b-form-checkbox
                                    :disabled="true"
                                    v-model="
                                        parentWebinar.config.moderate_message
                                    "
                                    :unchecked-value="false"
                                >
                                    Модерувати чат
                                    <i
                                        id="configuration-moderation-chat"
                                        class="bi bi-info-circle pt-3"
                                    ></i>
                                </b-form-checkbox>

                                <b-tooltip
                                    class="text-left"
                                    target="configuration-chat"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Дозволяє учасникам писати повідомлення в чат
                                    під час вебінару.
                                </b-tooltip>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-confirm-user"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Ви будете підтверджувати кожного учасника,
                                    перш ніж він увійде на вебінар.
                                </b-tooltip>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-link"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Учасники зможуть ділитися посиланнями в чаті
                                    під час вебінару.
                                </b-tooltip>
                                <b-tooltip
                                    class="text-left"
                                    target="configuration-moderation-chat"
                                    custom-class="custom-tooltip"
                                    offset="5"
                                    triggers="hover"
                                    :no-fade="true"
                                    :delay="{ show: 50, hide: 50 }"
                                >
                                    Всі повідомлення учасників перед публікацією
                                    буде схвалювати модератор.
                                </b-tooltip>
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">
                                Налаштування автовебінару
                            </div>
                            <b-row class="date-time-duration__group">
                                <InputGroup
                                    element="date"
                                    label="Дата початку"
                                    v-model="form.workShop.config.date"
                                    :disabled="isReadOnly"
                                    size="lg"
                                />
                                <InputGroup
                                    element="time"
                                    label="Час початку"
                                    v-model="form.workShop.config.date"
                                    :disabled="isReadOnly"
                                    size="lg"
                                />
                                <InputGroup
                                    element="duration"
                                    label="Тривалість"
                                    type="duration"
                                    v-model="form.workShop.config.duration"
                                    :disabled="isReadOnly"
                                    size="lg"
                                    class="w-25"
                                />
                            </b-row>
                            <!--                            <b-row>-->
                            <!--                                <b-col>-->
                            <!--                                    <b-form-group-->
                            <!--                                        id="input-group-3"-->
                            <!--                                        class="mt-4"-->
                            <!--                                    >-->
                            <!--                                        <b-form-checkbox-->
                            <!--                                            v-model="-->
                            <!--                                                form.workShop.config-->
                            <!--                                                    .is_chat_write-->
                            <!--                                            "-->
                            <!--                                        >-->
                            <!--                                            Дозволити писати в чаті-->
                            <!--                                        </b-form-checkbox>-->
                            <!--                                    </b-form-group>-->
                            <!--                                </b-col>-->
                            <!--                            </b-row>-->
                        </b-card-body>
                    </b-card>

                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <WorkshopTeamComponent
                                :form="form"
                                :team="team"
                                :team-presenter="teamPresenter"
                                :team-moderator="teamModerator"
                                :roles="roles"
                            />
                        </b-card-body>
                    </b-card>

                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="card__title">
                                Реєстраційні дані
                                <i
                                    id="tooltip-access"
                                    class="bi bi-info-circle"
                                ></i>
                            </div>
                            <b-tooltip
                                target="tooltip-access"
                                custom-class="webinar-tooltip"
                                offset="5"
                            >
                                Визначте, чи будете збирати реєстраційні дані
                                учасників.
                            </b-tooltip>
                            <b-form-radio-group
                                id="radio-group-2"
                                name="radio-sub-component"
                                class="mt-4"
                                v-model="form.workShop.config.access"
                            >
                                <b-form-radio
                                    :value="false"
                                    :disabled="isReadOnly"
                                    >Збирати
                                    <i
                                        id="toolooltip-data-store"
                                        class="bi bi-info-circle"
                                    ></i
                                ></b-form-radio>
                                <b-tooltip
                                    target="toolooltip-data-store"
                                    custom-class="webinar-tooltip"
                                    offset="5"
                                >
                                    Відвідувачі зможуть потрапити на трансляцію
                                    лише після заповнення реєстраційної форми.
                                    Всі дані будуть збережені та доступні для
                                    вас.
                                </b-tooltip>
                                <b-form-radio
                                    :value="true"
                                    :disabled="
                                        isReadOnly ||
                                        form.workShop.config.is_paid
                                    "
                                    >Не збирати
                                    <i
                                        id="toolooltip-open-for"
                                        class="bi bi-info-circle"
                                    ></i
                                ></b-form-radio>
                                <b-tooltip
                                    target="toolooltip-open-for"
                                    custom-class="webinar-tooltip"
                                    offset="5"
                                >
                                    Поля реєстраційної форми не будуть
                                    обов'язковими для заповнення.
                                </b-tooltip>
                            </b-form-radio-group>
                        </b-card-body>
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light-subtle p-3"
                        >
                            <InputGroup
                                label="Посилання, за яким будуть перенаправлені глядачі після вебінару"
                                v-model="form.workShop.completionRedirectUrl"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                                :maxLength="255"
                                :disabled="isReadOnly"
                                :is-error="
                                    v$.completionRedirectUrl?.$errors[0]
                                        ?.$message
                                "
                                :error-message="
                                    v$.completionRedirectUrl?.$errors[0]
                                        ?.$message
                                "
                                @blur="v$.completionRedirectUrl.$touch()"
                            />
                        </b-card-body>
                    </b-card>
                    <b-button
                        variant="primary"
                        size="lg"
                        pill
                        class="mt-3"
                        :disabled="form.processing || isReadOnly"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
            <ConfirmModal
                v-if="changeBaseWebinarConfirmModal"
                v-model="changeBaseWebinarConfirmModal"
                title="Зміна вебінару для показу"
                decline-label="Скасувати"
                accept-label="Так, змінити"
                @accept-btn="
                    () => {
                        getParentWebinarInfo(form.workShop.baseWorkShopId);
                    }
                "
                @decline-btn="
                    () => {
                        form.workShop.baseWorkShopId = initialBaseWorkShopId;
                        getParentWebinarInfo(form.workShop.baseWorkShopId);
                    }
                "
            >
                <template v-slot:description>
                    Якщо змінити поточний вебінар, усі незбережені зміни в
                    редакторі івентів буде втрачено. Ви впевнені, що бажаєте
                    продовжити?
                </template>
            </ConfirmModal>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.webinar-input-group {
    border-radius: 4px;
    border: 1px solid #1db954;
    background-color: #1db9540a;
    i {
        font-size: medium;
    }
    .webinar-form-input {
        background: transparent;
        border: none;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}
#input-1 {
    background-color: #1db9540a;
    border: 1px solid #1db954;
    border-radius: 4px;
}
</style>
