<script setup>
import { useI18n } from "vue-i18n";
import SelectInputGroup from "@/Components/dashboard/SelectInputGroup.vue";
import InputGroup from "@/Components/InputGroup.vue";
import { computed, nextTick, ref, watch } from "vue";
import SpeakerField from "@/Components/form/SpeakerField.vue";
import MyTeamModal from "@/Components/modals/MyTeamModal.vue";
import FileUploader from "@/Components/form/FileUploader.vue";

const { t } = useI18n();

const props = defineProps({
    form: Object,
    workshopTypes: Object,
    team: Object,
});

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});

const onSubmit = () => {
    emit("save");
};

const onReset = () => {
    form.reset();
};

const emit = defineEmits(["workShop-change", "save"]);

watch(
    () => props.form.workShop,
    (value) => {
        nextTick(() => emit("workShop-change", value));
    },
    { deep: true }
);

const myTeamModal = ref(false);

const saveSpeakers = (ids) => {
    const resultArray = [];
    props.team.forEach((obj) => {
        if (ids.includes(obj.id)) {
            const index = props.form.workShop.speakers.findIndex(
                (el) => el.id === obj.id
            );
            resultArray.push({
                ...obj,
                label:
                    index >= 0
                        ? props.form.workShop.speakers[index].label
                        : "Спікер",
            });
        }
    });
    props.form.workShop.speakers = resultArray;
    console.log("save speakers");
};
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="5">
                <b-form>
                    <FileUploader
                        :form="form"
                        :imagePreview="form.workShop.image"
                    />
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <SelectInputGroup
                                label="Статус"
                                v-model="form.workShop.status"
                                :options="statuses"
                                size="lg"
                                class="w-100"
                            />
                            <label class="form-label mt-3 fw-normal"
                                >Публічне посилання</label
                            >
                            <b-input-group class="webinar-input-group">
                                <b-form-input
                                    class="webinar-form-input"
                                    value=""
                                ></b-form-input>
                                <b-input-group-append>
                                    <!--                          <b-button variant="text">-->
                                    <!--                              <i class="bi bi-pencil-square"></i>-->
                                    <!--                          </b-button>-->
                                    <!--                          <b-button variant="text">-->
                                    <!--                              <i class="bi bi-front"></i>-->
                                    <!--                          </b-button>-->
                                </b-input-group-append>
                            </b-input-group>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light-subtle p-3"
                        >
                            <div class="fs">Основні дані</div>

                            <InputGroup
                                label="Назва майстеркласу"
                                v-model="form.workShop.title"
                                :options="statuses"
                                size="lg"
                                class="mt-3 w-100"
                            />

                            <InputGroup
                                element="textarea"
                                label="Про що ваш майстерклас?"
                                v-model="form.workShop.description"
                                :options="statuses"
                                :rows="3"
                                :max-rows="10"
                                size="lg"
                                class="mt-3 w-100"
                            />
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="overflow-hidden shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="fs">Налаштування майстеркласу</div>
                            <b-row class="mt-2 form-settings-dates">
                                <b-form-group
                                    class="form-settings-dates-item"
                                    label="Дата початку"
                                >
                                    <b-form-input
                                        type="date"
                                        v-model="form.workShop.config.date"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    class="form-settings-dates-item"
                                    label="Час початку"
                                >
                                    <b-form-input
                                        type="time"
                                        v-model="form.workShop.config.time"
                                    ></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    class="form-settings-dates-item"
                                    label="Тривалість"
                                >
                                    <b-form-input
                                        type="time"
                                        v-model="form.workShop.config.duration"
                                    ></b-form-input>
                                </b-form-group>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group
                                        id="input-group-3"
                                        class="mt-4"
                                    >
                                        <b-form-checkbox
                                            v-model="
                                                form.workShop.config
                                                    .is_chat_write
                                            "
                                        >
                                            Дозволити писати в чаті
                                        </b-form-checkbox>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="fs mb-2">
                                Спікери
                                <i
                                    id="tooltip-spikers"
                                    class="bi bi-info-circle"
                                ></i>
                            </div>
                            <b-tooltip
                                target="tooltip-spikers"
                                custom-class="webinar-tooltip"
                                offset="5"
                            >
                                У розділі "Спікери" ви маете змогу додавати
                                учасників, які будуть присутні на сесії.<br /><br />
                                За замовчуванням, всі присутні зазначаються як
                                "Спікер", проте ви маєте можливість змінити цю
                                назву.
                            </b-tooltip>
                            <div
                                class="d-flex justify-content-between align-items-end w-100"
                            >
                                <div class="d-flex flex-wrap gap-3">
                                    <SpeakerField
                                        v-for="speaker in form.workShop
                                            .speakers"
                                        :speaker="speaker"
                                    />
                                </div>
                                <b-button
                                    variant="danger"
                                    class="red-plus-button"
                                    @click="myTeamModal = !myTeamModal"
                                >
                                    <i class="bx bx-plus"></i>
                                </b-button>
                            </div>
                        </b-card-body>
                    </b-card>
                    <b-card no-body class="shadow-none mt-4">
                        <b-card-body
                            class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                        >
                            <div class="fs">
                                Доступ
                                <i
                                    id="tooltip-access"
                                    class="bi bi-info-circle"
                                ></i>
                            </div>
                            <b-tooltip
                                target="tooltip-access"
                                custom-class="webinar-tooltip"
                                offset="5"
                            >
                                Визначте, як користувачі зможуть підключитися до
                                вебінару.
                            </b-tooltip>
                            <b-form-radio-group
                                v-model="form.workShop.config.access"
                                name="radio-sub-component"
                                class="mt-4"
                            >
                                <b-form-radio :value="false">
                                    Збирати дані
                                    <i class="bi bi-info-circle"></i>
                                </b-form-radio>
                                <b-form-radio :value="true">
                                    Відкрито для всіх
                                    <i
                                        id="toolooltip-open-for"
                                        class="bi bi-info-circle"
                                    ></i>
                                </b-form-radio>
                                <b-tooltip
                                    target="toolooltip-open-for"
                                    custom-class="webinar-tooltip"
                                    offset="5"
                                >
                                    Будь-хто зможе приєднатися до трансляції за
                                    посиланням
                                </b-tooltip>
                            </b-form-radio-group>
                        </b-card-body>
                    </b-card>

                    <MyTeamModal
                        v-model="myTeamModal"
                        :team="team"
                        :speakers="form.workShop.speakers"
                        @on-save="saveSpeakers"
                    />

                    <b-button
                        :disabled="form.processing"
                        variant="success"
                        size="lg"
                        pill
                        class="mt-3"
                        @click="onSubmit"
                    >
                        Зберегти
                    </b-button>
                </b-form>
            </b-col>
        </b-row>
    </b-container>
</template>

<style lang="scss">
.webinar-input-group {
    border-radius: 4px;
    border: 1px solid #1db954;
    background-color: #1db9540a;
    i {
        font-size: medium;
    }
    .webinar-form-input {
        background: transparent;
        border: none;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}
</style>
