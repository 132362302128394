<script setup>
import { Head, Link, router, usePage } from "@inertiajs/vue3";

import PageHeader from "@/Components/PageHeader.vue";
import "flatpickr/dist/flatpickr.css";
import StatusBage from "@/Components/StatusBage.vue";
import { useI18n } from "vue-i18n";
import PageContentCard from "@/Components/PageContentCard.vue";
import { computed, ref, watch, watchEffect } from "vue";
import DiskUsageProgress from "@/Components/DiskUsageProgress.vue";
import DropdownButtons from "@/Components/buttons/DropdownButtons.vue";
import ShareModal from "@/Components/modals/ShareModal.vue";
import noImage from "../../../images/no-image.png";
import {
    useDateFormatter,
    useWorkshopStatus,
} from "@/composables/useDateFormatter.js";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    user: Object,
    workshops: Object,
    workshopTypes: Object,
    workshopStatistic: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

const { formatDate } = useDateFormatter();
const { getWorkshopStatus } = useWorkshopStatus();

const page = {
    title: t("t-workshops"),
    breadcrumbs: [
        {
            text: "Дашборд",
            href: route("dashboard"),
        },
        {
            text: "Воркшопи",
            active: true,
        },
    ],
};

const statuses = computed(() => {
    return props.workshopTypes && props.workshopTypes.length > 0
        ? props.workshopTypes.map((item) => {
              return { value: item, text: t(item) };
          })
        : [];
});
const status = ref("ALL");

watchEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    status.value = urlParams.get("status") || "ALL";
});

watch(status, (value) => {
    router.visit(route("workshops.workshops", { status: status.value }), {
        method: "get",
        preserveState: true,
    });
});

const mainProps = ref({ blank: true, width: 75, height: 75 });

function generateEditLink(type, id) {
    return route("workshops.edit", { type: type.toLowerCase(), id: id });
    // switch (type) {
    //   case 'WEBINAR': {
    //     return route('workshops.edit', {"type": type, id: id});
    //   }
    //   case 'AUTO_WEBINAR': {
    //     return route('workshops.edit', {"type": type, id: id});
    //   }
    //   case 'MASTERCLASS': {
    //     return route('workshops.edit', {"type": type, id: id});
    //   }
    // }
}

const pageGlobal = usePage();
const storageStatistic = computed(() =>
    pageGlobal?.props?.auth?.storageStatistic
        ? pageGlobal.props.auth.storageStatistic
        : { balance: 0 }
);

const createLinks = [
    { label: "Створити вебінар", link: "/workshops/create/webinar" },
    { label: "Створити автовебінар", link: "/workshops/create/auto-webinar" },
    { label: "Створити майстерклас", link: "/workshops/create/masterclass" },
];

const shareLinkModal = ref(false);
const shareLink = ref("");

function handleShare(row) {
    shareLinkModal.value = !shareLinkModal.value;
    shareLink.value = row.link;
}

const deleteWorkshopModal = ref(false);

const removeWorkshop = () => {
    router.visit(route("workshops.delete", { id: tmpId.value }), {
        method: "post",
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            console.log(response);
        },
    });
};

const tmpId = ref(null);

const setPage = (e, page) => {
    router.visit(route("workshops.workshops", { page: page }), {
        method: "get",
        preserveState: false,
        preserveScroll: false,
        forceFormData: true,
    });
};
</script>

<template>
    <div>
        <Head title="Воркшопи" />

        <PageHeader :title="page.title" :breadcrumbs="page.breadcrumbs" />

        <PageContentCard :title="$t('t-workshops')">
            <template #header-start>
                <b-form-select
                    v-model="status"
                    :placeholder="''"
                    :options="statuses"
                    size="sm"
                    ref="input"
                    class="ms-3"
                    v-uid
                />
            </template>
            <template #header-end>
                <div class="header-end">
                    <DiskUsageProgress
                        :percent="storageStatistic.percent"
                        :used="storageStatistic.spaceUsage"
                        :capacity="storageStatistic.capacity"
                    />
                    <div v-if="!isReadOnly">
                        <DropdownButtons :buttons="createLinks">
                            <template v-slot:toggle-button>
                                <b-button pill size="lg" class="outline-dark">
                                    {{ $t("btn-create") }}
                                    <i class="ph-plus"></i>
                                </b-button>
                            </template>
                        </DropdownButtons>
                    </div>
                </div>
            </template>
            <template #default>
                <div class="module-table workshops-list">
                    <div class="module-table__header border-bottom pb-2">
                        <div
                            class="module-table__col module-table__col--title text-uppercase opacity-50"
                        >
                            {{ $t("t-broadcast") }}
                        </div>
                        <div
                            class="module-table__col module-table__col--role text-uppercase opacity-50"
                        >
                            {{ $t("t-role") }}
                        </div>
                        <div
                            class="module-table__col module-table__col--date text-uppercase opacity-50"
                        >
                            {{ $t("t-date") }}
                        </div>
                        <div
                            class="module-table__col module-table__col--participants text-uppercase opacity-50"
                        >
                            {{ $t("t-participants") }}
                        </div>
                        <div
                            class="module-table__col module-table__col--status text-uppercase opacity-50"
                        >
                            {{ $t("t-status") }}
                        </div>
                        <div
                            class="module-table__col module-table__col--type text-uppercase opacity-50"
                        >
                            {{ $t("t-type") }}
                        </div>
                        <div
                            class="module-table__col module-table__col--actions text-uppercase opacity-50"
                        >
                            {{ $t("t-actions") }}
                        </div>
                    </div>
                    <div
                        v-for="workshop of workshops.data"
                        :key="workshop.id"
                        class="module-table__row border-bottom py-3"
                    >
                        <!--            <div class="workshops-tags">-->
                        <!--                <div v-if="workshop.status === 'DRAFT'" class="workshops-tag">-->
                        <!--                    {{$t(workshop.status)}}-->
                        <!--                </div>-->
                        <!--                <div v-if="workshop.status === 'DRAFT'" class="workshops-tag">-->
                        <!--                    Спікер-->
                        <!--                </div>-->
                        <!--            </div>-->
                        <Link
                            :href="generateEditLink(workshop.type, workshop.id)"
                            class="module-table__col module-table__col--title"
                        >
                            <div class="module-table__title-image">
                                <b-img
                                    :src="
                                        workshop.image
                                            ? workshop.image
                                            : noImage
                                    "
                                    :title="workshop.title"
                                    :alt="workshop.title"
                                    rounded
                                    fluid
                                ></b-img>
                            </div>
                            <div class="module-table__title-text">
                                <span class="table-col__title">{{
                                    workshop.title
                                }}</span>
                                <span class="table-col__subtitle">{{
                                    workshop.description &&
                                    workshop.description.length > 0
                                        ? workshop.description
                                        : "без опису"
                                }}</span>
                            </div>
                        </Link>
                        <div class="module-table__col module-table__col--role">
                            <span
                                v-if="workshop.current_role"
                                class="table-col__time"
                                >{{ t(workshop.current_role) }}</span
                            >
                        </div>
                        <div class="module-table__col module-table__col--date">
                            <span v-if="workshop.type !== 'MASTERCLASS'" class="table-col__time">{{
                                formatDate(workshop.date, "HH:mm")
                            }}</span>
                            <span v-if="workshop.type !== 'MASTERCLASS'" class="table-col__day">{{
                                formatDate(workshop.date, "d MMMM yyyy")
                            }}</span>
                        </div>
                        <div
                            class="module-table__col module-table__col--participants"
                        >
                            <i class="ri-user-fill"></i>{{ workshop.online }}/{{
                                workshop.participants.all
                            }}
                        </div>
                        <div
                            class="module-table__col module-table__col--status"
                        >
                            <StatusBage
                                :type="getWorkshopStatus(workshop).status"
                            ></StatusBage>
                            <b-button
                                v-if="
                                    getWorkshopStatus(workshop).showLiveButton
                                "
                                class="workshop-join__btn"
                                :href="workshop.linkCabinet"
                                target="_blank"
                                >Приєднатися</b-button
                            >
                            <b-button
                                v-if="workshop.type === 'MASTERCLASS'"
                                class="masterclass-view__btn"
                                :href="route('workshops.masterclass.view', {
                                        token: workshop.id,
                                    })"
                                target="_blank"
                                >Переглянути</b-button
                            >
                        </div>
                        <div class="module-table__col module-table__col--type">
                            {{ $t(workshop.type) }}
                        </div>
                        <div
                            class="module-table__col module-table__col--actions"
                        >
                            <div class="dropdown flex-shrink-0">
                                <b-button
                                    variant="text-variant"
                                    size="sm"
                                    class="btn-icon dropdown-menu__button"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    pill
                                    aria-expanded="false"
                                >
                                    <i class="bi bi-three-dots-vertical"></i>
                                </b-button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <span
                                            class="dropdown-item"
                                            @click="() => handleShare(workshop)"
                                        >
                                            <i
                                                class="ri-upload-2-line align-baseline me-1"
                                            ></i>
                                            Поділитись
                                        </span>
                                    </li>
                                    <li>
                                        <span
                                            v-if="!isReadOnly"
                                            class="dropdown-item"
                                            @click="
                                                deleteWorkshopModal = true;
                                                tmpId = workshop.id;
                                            "
                                        >
                                            <i
                                                class="bi bi-trash3 align-baseline me-1"
                                            ></i>
                                            Видалити
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <ConfirmModal
                                v-if="deleteWorkshopModal"
                                v-model="deleteWorkshopModal"
                                title="Видалення воркшопа"
                                decline-label="Скасувати"
                                accept-label="Так, видалити"
                                @accept-btn="() => removeWorkshop()"
                                @decline-btn="() => console.log('decline')"
                            >
                                <template v-slot:description>
                                    Після видалення воркшопа його не можна буде повернути. Ви впевнені, що хочете продовжити?
                                </template>
                            </ConfirmModal>
                        </div>
                    </div>
                </div>
                <b-pagination
                    v-if="
                        workshops.pagination && workshops.pagination.total > 0
                    "
                    class="mt-4"
                    :total-rows="workshops.pagination.total"
                    :per-page="workshops.pagination.per_page"
                    aria-controls="my-table"
                    @page-click="setPage"
                ></b-pagination>
                <ShareModal v-model="shareLinkModal" :shared-link="shareLink" />
            </template>
        </PageContentCard>
    </div>
</template>

<style lang="scss" scoped>
#shareLink {
    text-align: center;
    border-radius: 8px;
    border: 1px solid var(--green, #1db954);
    background: linear-gradient(
            0deg,
            rgba(29, 185, 84, 0.04) 0%,
            rgba(29, 185, 84, 0.04) 100%
        ),
        #fff;
}
.workshops-list {
    .module-table__row {
        position: relative;
        .workshops-tags {
            position: absolute;
            top: 4px;
            left: 4px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            .workshops-tag {
                font-family: e-Ukraine;
                background: var(--green, #1db954);
                padding: 2px 8px;
                border-radius: 4px;
                color: white;
                text-align: center;
            }
        }
    }
    .module-table__col--title {
        display: flex;
        flex: 1;
        align-items: center;
        padding-right: 16px;

        @media (max-width: 992px) {
            width: 100%;
        }

        .module-table__title-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6px;
            flex-wrap: nowrap;
            padding: 10px 16px;
            width: calc(100% - 125px);

            .table-col__title {
                font-family: e-Ukraine;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0;
                text-align: left;
                color: #1e1e1e;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }

            .table-col__subtitle {
                font-family: e-Ukraine;
                font-size: 14px;
                font-weight: 200;
                line-height: 14px;
                letter-spacing: 0;
                text-align: left;
                color: #1e1e1e;
                opacity: 0.5;
                display: -webkit-box;
                display: -moz-box;
                display: flex;
                -webkit-line-clamp: 2;
                -moz-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
                -moz-box-orient: vertical;
                box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .module-table__col--role {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 10%;

        @media (max-width: 992px) {
            width: 50%;
        }
    }
    .module-table__col--date {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 10%;

        @media (max-width: 992px) {
            width: 50%;
        }
    }

    .module-table__col--status {
        width: 135px;
        padding-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        .workshop-join__btn {
            width: fit-content;
            padding: 4px 10px;
            border-radius: 45px;
            background: #da1414;
            border: 1px solid #da1414;
            font-family: e-Ukraine;
            font-size: 11px;
            font-weight: 300;
            line-height: 15.4px;
            text-align: center;
            color: #ffffff;
            display: flex;
            align-items: center;
            gap: 6px;
            &::before {
                content: "";
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background: #ffffff;
            }
        }
        .masterclass-view__btn {
            width: 100%;
            padding: 4px 10px;
            border-radius: 45px;
            background: var(--green, #1db954);
            border: 1px solid var(--green, #1db954);
            font-family: e-Ukraine;
            font-size: 11px;
            font-weight: 300;
            line-height: 15.4px;
            text-align: center;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        @media (max-width: 992px) {
            width: 50%;
        }
    }

    .module-table__col--participants {
        width: 10%;
        display: flex;
        align-items: center;

        font-family: e-Ukraine;
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;

        i {
            font-size: large;
            opacity: 0.5;
        }
    }

    .module-table__col--actions {
        width: 30px !important;
        justify-content: center !important;
        //i {
        //  font-size: large;
        //}
    }

    .module-table__col--type {
        width: 10%;
        font-family: e-Ukraine;
        font-size: 13px;
        font-weight: 400;
        line-height: 18.2px;
        text-align: left;
    }

    .module-table__title-image {
        width: 128px;
        height: 72px;
        border-radius: 8px;
        border: 1px solid #ebebeb;
        background: #f7f7f8;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 128px;
            max-height: 72px;
        }
    }
}
</style>
