<script setup>
import { ref } from "vue";
import { Link, router, usePage } from "@inertiajs/vue3";
import { useI18n } from "vue-i18n";
import ConfirmModal from "@/Components/modals/ConfirmModal.vue";
import CourseModuleSelector from "@/Components/form/CourseModuleSelector.vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const props = defineProps({
    statuses: Object,
    team: Object,
    form: Object,
    certificates_type: Object,
    users: Object,
    students: Object,
});

const { t } = useI18n();

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const workshopRoles = props.form?.course?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles, workshopRoles, "CURATOR");

const checkAccess = (userId, moduleId) => {
    console.log(userId, moduleId);
    let rez = false;
    for (const access in props.users.access) {
        if (userId === access.user_id && moduleId === access.module_id) {
            rez = true;
            console.log(rez);
            return rez;
        }
    }
    return rez;
};

function handleCheckboxChange(data) {
    const userId = data.user_id;
    const moduleId = data.module_id;
    router.visit(route("courses.grant-module-access"), {
        method: "post",
        data: {
            userId: userId,
            moduleId: moduleId,
            status: event.target.checked,
        },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            // props.form.course.contentItems = response.props.course.contentItems;
            // router.visit(router.page.url);
        },
    });
}

const grantAccess = (courseUserId) => {
    console.log("courseUserId:", courseUserId);
    router.visit(route("courses.grant-access"), {
        method: "post",
        data: { courseUserId: courseUserId },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            // props.form.course.contentItems = response.props.course.contentItems;
            // router.visit(router.page.url);
        },
    });
};

const addUserToCourse = (courseUserId) => {
    console.log("courseUserId:", courseUserId);
    router.visit(route("courses.add-user"), {
        method: "post",
        data: { courseUserId: courseUserId, courseId: props.form.course.id },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            // props.form.course.contentItems = response.props.course.contentItems;
            // router.visit(router.page.url);
        },
    });
};

const addStudent = ref({});

function removeUser(userId) {
    router.visit(route("courses.remove-user"), {
        method: "post",
        data: { id: userId },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            // props.form.course.contentItems = response.props.course.contentItems;
            // router.visit(router.page.url);
        },
    });
}

const deleteCourseUser = ref(false);
</script>

<template>
    <b-container fluid class="tab-form">
        <b-form>
            <div class="module-table course-users-list">
                <div class="module-table__header border-bottom pb-2">
                    <div
                        class="module-table__col module-table__col--email text-uppercase opacity-50"
                    >
                        Email
                    </div>
                    <div
                        class="module-table__col module-table__col--name text-uppercase opacity-50"
                    >
                        ім'я
                    </div>
                    <div
                        class="module-table__col module-table__col--phone text-uppercase opacity-50"
                    >
                        телефон
                    </div>
                    <!--          <div class="module-table__col module-table__col&#45;&#45;payment text-uppercase opacity-50">-->
                    <!--            оплата-->
                    <!--          </div>-->
                    <div
                        class="module-table__col module-table__col--modules text-uppercase opacity-50"
                    >
                        Доступ до модулів
                        <div class="d-flex mt-1">
                            <!--              <template v-for="module of form.course.contentItems">-->
                            <!--                <span class="me-1" v-if="module.module_type === 'MULTI_LESSON'">{{ module.title }}</span>-->
                            <!--                <span class="me-1" v-if="module.module_type === 'SINGLE_LESSON'">{{ module.lessons[0].title }}</span>-->
                            <!--                <b-form-checkbox v-model="moduleChecked1" value="true" unchecked-value="false"></b-form-checkbox>-->
                            <!--              </template>-->
                        </div>
                    </div>
                    <div
                        class="module-table__col module-table__col--actions text-uppercase"
                    >
                        <b-button
                            variant="primary"
                            pill
                            v-b-modal.grantAccessModal1
                            v-if="students"
                            :disabled="isReadOnly"
                        >
                            <!-- && Object.keys(students).length > 0 -->
                            Додати студента
                        </b-button>
                        <b-modal
                            id="grantAccessModal1"
                            hide-footer
                            hide-header
                            centered
                            v-if="students"
                        >
                            <!-- && Object.keys(students).length > 0 -->
                            <div class="modal-body">
                                <div class="grand-modal-title">
                                    <i class="ph-warning"></i>
                                    <h4>Підтвердіть надання доступу</h4>
                                </div>
                                <p class="grand-modal-desc">
                                    Якщо ви надасте студенту доступ до курсу,
                                    його вже не можна буде скасувати.
                                </p>
                                <b-form-select
                                    v-if="
                                        students &&
                                        Object.keys(students).length > 0
                                    "
                                    v-model="addStudent"
                                    :placeholder="'Обрати студента'"
                                    :options="students"
                                    size="xl"
                                    ref="input"
                                    v-uid
                                />
                                <p v-else class="empty-student-list">
                                    Список студентів поки що порожній.
                                </p>
                                <div class="grand-modal-actions">
                                    <b-button
                                        class="outline-dark"
                                        pill
                                        v-b-modal.grantAccessModal1
                                    >
                                        Скасувати
                                    </b-button>
                                    <b-button
                                        @click="addUserToCourse(addStudent)"
                                        :disabled="
                                            !(
                                                students &&
                                                Object.keys(students).length > 0
                                            )
                                        "
                                        class="grand-modal-accept m-2"
                                        pill
                                        v-b-modal.grantAccessModal1
                                    >
                                        Надати доступ
                                    </b-button>
                                </div>
                            </div>
                        </b-modal>
                        <div class="d-flex mt-1">
                            <!--              <template v-for="module of form.course.contentItems">-->
                            <!--                <span class="me-1" v-if="module.module_type === 'MULTI_LESSON'">{{ module.title }}</span>-->
                            <!--                <span class="me-1" v-if="module.module_type === 'SINGLE_LESSON'">{{ module.lessons[0].title }}</span>-->
                            <!--                <b-form-checkbox v-model="moduleChecked1" value="true" unchecked-value="false"></b-form-checkbox>-->
                            <!--              </template>-->
                        </div>
                    </div>
                </div>
                <div
                    v-for="user of users.data"
                    :key="user.id"
                    class="module-table__row border-bottom py-3"
                >
                    <div class="module-table__col module-table__col--email">
                        <!--                        <span-->
                        <!--                            class="module-table__email-bage"-->
                        <!--                            :class="[user.status === 'USER_NEW' ? 'module-table__email-bage-new' : '']"-->
                        <!--                        >-->
                        <!--                            {{ t(user.status) }}-->
                        <!--                        </span>-->
                        {{ user.email }}
                    </div>
                    <div class="module-table__col module-table__col--name">
                        {{ user.name }}
                    </div>
                    <div class="module-table__col module-table__col--phone">
                        {{ user.phone }}
                    </div>
                    <!--          <div class="module-table__col module-table__col&#45;&#45;payment">-->
                    <!--            <a v-if="user.payment_check_name" :href="user.payment_check_download">{{ user.payment_check_name }}<i class="ph-arrow-down-bold"></i></a>-->
                    <!--          </div>-->
                    <div
                        class="module-table__col module-table__col--modules d-flex"
                    >
                        <CourseModuleSelector
                            :modules="form.course.contentItems"
                            :user="user"
                            :disabled="isReadOnly"
                            @save-modules="handleCheckboxChange"
                        />
                    </div>
                    <div class="module-table__col module-table__col--actions">
                        <div class="dropdown flex-shrink-0">
                            <b-button
                                :disabled="isReadOnly"
                                variant="link"
                                size="sm"
                                class="btn-icon dropdown-menu__button"
                                role="button"
                                data-bs-toggle="dropdown"
                                pill
                                aria-expanded="false"
                            >
                                <i class="bi bi-three-dots-vertical"></i>
                            </b-button>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li v-if="user.status === 'USER_NEW'">
                                    <span
                                        class="dropdown-item"
                                        v-b-modal.grantAccessModal
                                    >
                                        Надати доступ
                                    </span>
                                </li>
                                <li>
                                    <span
                                        class="dropdown-item"
                                        @click="deleteCourseUser = true"
                                    >
                                        <i
                                            class="bi bi-trash3 align-baseline me-1"
                                        ></i>
                                        Видалити
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ConfirmModal
                        v-model="deleteCourseUser"
                        title="Видалення студента"
                        decline-label="Скасувати"
                        accept-label="Так, видалити"
                        @accept-btn="() => removeUser(user.id)"
                        @decline-btn="() => console.log('decline')"
                    >
                        <template v-slot:description>
                            Ви впевнені, що хочете видалити цього студента?
                        </template>
                    </ConfirmModal>
                    <b-modal
                        id="grantAccessModal"
                        hide-footer
                        hide-header
                        centered
                    >
                        <div class="modal-body">
                            <div class="grand-modal-title">
                                <i class="ph-warning"></i>
                                <h4>Підтвердіть надання доступу</h4>
                            </div>
                            <p class="grand-modal-desc">
                                Якщо ви надасте студенту доступ до курсу, його
                                вже не можна буде скасувати.
                            </p>
                            <div class="grand-modal-actions">
                                <b-button
                                    class="outline-dark"
                                    pill
                                    v-b-modal.grantAccessModal
                                >
                                    Скасувати
                                </b-button>
                                <b-button
                                    @click="grantAccess(user.user_id)"
                                    class="grand-modal-accept m-2"
                                    pill
                                    v-b-modal.grantAccessModal
                                >
                                    Надати доступ
                                </b-button>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </b-form>
    </b-container>
</template>

<style lang="scss">
.grand-modal-actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
}

.grand-modal-title {
    color: #da7f14;
    display: flex;
    flex-direction: row;

    i {
        font-size: xxx-large;
    }

    h4 {
        color: #da7f14;
    }
}

.grand-modal-desc {
    margin-left: 50px;
    color: dimgray;
}

.grand-modal-accept {
    background-color: var(--green, #1db954);
    border: none;
}

.grand-modal-accept:hover {
    background-color: var(--green, #1db954);
    opacity: 0.8;
}

.grand-modal-accept:hover {
    background-color: var(--green, #1db954);
    opacity: 0.8;
}

.course-users-list {
    .module-table__col--email {
        display: flex;
        width: 25%;

        //.module-table__email-bage {
        //    width: 85px;
        //    font-size: smaller;
        //    text-align: center;
        //    background-color: var(--green, #1DB954);
        //    color: white;
        //    padding: 2px;
        //    margin-right: 12px;
        //    border-radius: 5px;
        //}
        //
        //.module-table__email-bage-new {
        //    background: var(--purple, #F24822);
        //}
    }

    .module-table__col--name {
        width: 10%;
    }

    .module-table__col--phone {
        width: 10%;
    }
    .module-table__col--payment {
        width: 10%;

        i {
            color: green;
        }
    }

    .module-table__col--modules {
        width: 30%;
    }

    .module-table__col--actions {
        width: 180px !important;
        display: flex;
        align-items: center;
        justify-content: flex-end !important;

        //i {
        //    margin: 0 10px;
        //    font-size: large;
        //    color: #C2C2C2;
        //}

        //button {
        //    background-color: var(--green, #1DB954);
        //}
    }
    @media only screen and (max-width: 1368px) {
        .module-table__col--email {
            width: 20%;
        }
        .module-table__col--name {
            flex: 1;
        }
        .module-table__col--phone {
            width: 20%;
        }
        .module-table__col--modules {
            width: 20% !important;
            flex-wrap: wrap;
        }
    }
}

.webinar-input-group {
    border-radius: 4px;
    border: 1px solid #1db954;
    background-color: #1db9540a;

    i {
        font-size: medium;
    }

    .webinar-form-input {
        background: transparent;
        border: none;
    }
}

.tab-form {
}

.image-uploader {
    max-width: 420px;
    height: 240px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.image-uploader__description {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.image-uploader__title {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 14px */
}

.image-uploader__subtitle {
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 140%; /* 16.8px */
    opacity: 0.4;
}

#input-1 {
    background-color: #1db9540a;
    border: 1px solid #1db954;
    border-radius: 4px;
}
.empty-student-list {
    font-family: e-Ukraine;
    color: var(--dark, #1e1e1e);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    margin: 20px 0;
}
</style>
