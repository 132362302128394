<script setup>
import {computed, onBeforeUnmount, ref} from "vue";
import FileUploader from "@/Components/form/FileUploader.vue";
import { useForm, usePage } from "@inertiajs/vue3";
import InputGroup from "@/Components/InputGroup.vue";
import { useAPIForm } from "@/composables/useApiForm.js";
import { toast } from "vue3-toastify";
import {helpers, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

const props = defineProps({
    parentId: String,
    modelValue: Boolean,
    routeArgs: Array,
    callBack: Function,
});

const emit = defineEmits(["update:modelValue", "upload-complete"]);

const isVisible = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});

const tmpPresign = ref(null);

const form = useAPIForm({
    id: props.parentId,
    name: "",
    description: "",
    preview: null,
    duration: null,
    storageData: {},
});

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    }
}

const v$ = useVuelidate(rules, form, { $lazy: true });

const handleSend = () => {
    v$.value.$touch();
    if (!v$.value.$invalid) {
        if (isCustomPreview.value) {
            const reader = new FileReader();
            reader.readAsDataURL(customPreviewForm.filePreview);
            reader.onloadend = function () {
                submitForm(reader.result);
            };
        } else {
            submitForm(filePreview.value);
        }
    }
};

const uploadToS3 = async (file, presign) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
        const response = await axios.put(presign, file, {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
            withCredentials: false,
            mode: "no-cors",
        });

        if (response.status === 200) {
            console.log("File successfully uploaded to S3");
            return response.data;
        } else {
            console.error("Error uploading file to S3:", response.statusText);
            return null;
        }
    } catch (error) {
        console.error("Error uploading file to S3:", error.message);
        return null;
    }
};

const getPresign = async (url) => {
    let response = await fetch(url);
    if (response.ok) {
        let json = await response.json();
        return json;
    } else {
        if (response.status === 403) {
            toast.error("Ліміт диска вичерпано", {
                autoClose: 3000,
                position: toast.POSITION.BOTTOM_CENTER,
            });
        }
        console.log("Ошибка HTTP: " + response.status);
        isVisible.value = !isVisible.value;
    }
};

const onFormSubmitEmpty = () => {
    form.reset();
    file.value = null;
    fileInput.value = null;
    emit("uploadComplete", tmpPresign.value);
    tmpPresign.value = null;
    isLoading.value = false;
    isVisible.value = !isVisible.value;
};

const page = usePage();
const user = page.props.auth.user;

const submitForm = async (base64) => {
    // console.log(route(...props.routeArgs));
    isLoading.value = true;
    const presign = await getPresign(
        route("presigned", {
            type: "video",
            file: form.video.name,
            file_name: form.name,
            file_size: form.video.size,
            file_description: form.description,
        })
    );
    tmpPresign.value = presign;
    console.log(presign);
    user.storageStatistic = presign.discUsage;
    console.log("start upload");
    uploadToS3(form.video, presign.url);
    console.log("endUpload");
    form.preview = base64;
    form.duration = duration;
    console.log("Start update storage data");
    form.storageData = presign.storageData;
    form.storageData.description = form.name;
    if (props.routeArgs) {
        form.post(route(...props.routeArgs), {
            onSuccess: onFormSubmit,
        });
    } else {
        onFormSubmitEmpty();
    }
    console.log("End update storage data");
    // console.log(...props.routeArgs);
    // isLoading.value = false;
};

const onFormSubmit = (response) => {
    console.log("onFormSubmit", response);

    form.reset();
    customPreviewForm.reset();
    file.value = null;
    fileInput.value = null;
    filePreview.value = "";
    isCustomPreview.value = false;
    emit("uploadComplete", response);
    tmpPresign.value = null;
    isLoading.value = false;
    isVisible.value = !isVisible.value;
};

async function getThumbnailForVideo(videoUrl) {
    failedGeneratePreview.value = false
    isLoading.value = true;
    const video = document.createElement("video");
    const canvas = document.createElement("canvas");
    video.style.display = "none";
    canvas.style.display = "none";

    try {
        await new Promise((resolve, reject) => {
            const timeoutId = setTimeout(() => {
                reject(new Error('Timeout'));
            }, 5000);
            video.addEventListener("loadedmetadata", () => {
                clearTimeout(timeoutId);
                duration.value = Math.floor(video.duration);
                video.width = video.videoWidth;
                video.height = video.videoHeight;
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                video.currentTime = selectedTime.value;
            });

            video.addEventListener("seeked", () => resolve());

            video.src = videoUrl;

            video.onerror = (error) => {
                clearTimeout(timeoutId);
                reject(new Error('Помилка завантаження відео'));
            };

            video.onabort = () => {
                clearTimeout(timeoutId);
                reject(new Error('Завантаження відео перервано'));
            };
        });

        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        const thumbnailBase64 = canvas.toDataURL("image/png");

        const regex = /^data:image\/(png|jpeg|jpg|gif);base64,/;
        if (!regex.test(thumbnailBase64)) {
            throw new Error('Зображення не валідне');
        }

        isLoading.value = false;
        return thumbnailBase64;

    } catch (error) {
        isLoading.value = false;
        return null;
    }
}

const customPreviewForm = useForm({
    filePreview: "",
});

const selectedTime = ref(0);
const isCustomPreview = ref(false);
const isLoading = ref(false);
const file = ref(null);
const fileInput = ref(null);
const duration = ref(0);
const filePreview = ref("");
const failedGeneratePreview = ref(false);

const selectedTimePreview = computed(() => {
    const hours = Math.floor(selectedTime.value / 3600);
    const minutes = Math.floor((selectedTime.value % 3600) / 60);
    const remainingSeconds = selectedTime.value % 60;

    if (hours > 0) {
        return `${hours} год. ${minutes} хв. ${remainingSeconds} сек.`;
    } else if (minutes > 0) {
        return `${minutes} хв. ${remainingSeconds} сек.`;
    } else {
        return `${remainingSeconds} сек.`;
    }
});

const isVideo = computed(
    () => !!(file.value && file.value.type.startsWith("video/"))
);

const handleSliderChange = async () => {
    fileUrl = URL.createObjectURL(file.value);
    const thumbnail = await getThumbnailForVideo(fileUrl);
    if (thumbnail) {
        filePreview.value = thumbnail;
    } else {
        failedGeneratePreview.value = true
        isCustomPreview.value = true
    }
};

const openFilePicker = () => {
    if (fileInput) {
        fileInput.value.click();
    }
};

const handleDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
};

const handleDrop = async (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];

    if (droppedFile) {
        file.value = droppedFile;
    }
    if (!isVideo.value) {
        file.value = null;
        return;
    }
    fileUrl = URL.createObjectURL(file.value);
    const thumbnail = await getThumbnailForVideo(fileUrl);
    if (thumbnail) {
        filePreview.value = thumbnail;
    } else {
        failedGeneratePreview.value = true
        isCustomPreview.value = true
    }
    form.video = droppedFile;
    //form.name = droppedFile.name ? droppedFile.name : ''
};

const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
        file.value = selectedFile;
        if (!isVideo.value) {
            file.value = null;
            return;
        }
        fileUrl = URL.createObjectURL(file.value);
        const thumbnail = await getThumbnailForVideo(fileUrl);
        if (thumbnail) {
            filePreview.value = thumbnail;
        } else {
            failedGeneratePreview.value = true
            isCustomPreview.value = true
        }
        form.video = selectedFile;
    }
    //form.name = selectedFile.name ? selectedFile.name : ''
};

const removeImage = () => {
    file.value = null;
    selectedTime.value = 0;
    filePreview.value = "";
};

let fileUrl = null;

onBeforeUnmount(() => {
    if(fileUrl) URL.revokeObjectURL(fileUrl)
});

</script>

<template>
    <b-modal
        v-model="isVisible"
        hide-footer
        hide-header
        centered
        :no-close-on-backdrop="isLoading"
    >
        <div class="modal-body">
            <div class="d-flex align-items-center justify-content-between mb-2">
                <h3>Додати відео</h3>
                <h3>
                    <i
                        class="bi bi-x-lg cursor-pointer"
                        @click="isVisible = !isVisible"
                    ></i>
                </h3>
            </div>
            <b-spinner
                v-if="isLoading"
                variant="primary"
                label="Spinning"
                class="wait-spinner"
            ></b-spinner>
            <div
                v-if="file && isVideo && !isCustomPreview && filePreview"
                class="video-uploader__preview"
            >
                <div
                    class="video-uploader__preview--delete"
                    @click="removeImage"
                >
                    <i class="bi bi-trash3"></i>
                </div>
                <img
                    class="video-uploader__preview--image"
                    :src="filePreview"
                    alt="Превью"
                />
            </div>
            <div
                v-else
                class="video-uploader"
                @dragover.prevent="handleDragOver"
                @drop.prevent="handleDrop"
                @click="openFilePicker"
            >
                <i class="ph-play-circle-bold fs-1"></i>
                <div class="video-uploader__title">
                    {{
                        !isVideo
                            ? "Завантажте або перетягніть відео"
                            : file.name
                    }}
                </div>
                <div class="video-uploader__subtitle">
                    {{
                        !isVideo
                            ? "Рекомендований формат 16:9, mp4"
                            : `Тип файлу: ${file.type}`
                    }}
                </div>
                <input
                    ref="fileInput"
                    type="file"
                    accept="video/*"
                    style="display: none"
                    @change="handleFileChange"
                />
            </div>
            <div v-if="!isCustomPreview">
                <input
                    type="range"
                    min="1"
                    :max="duration"
                    v-model="selectedTime"
                    :disabled="!isVideo"
                    class="preview-time-slider"
                    @change="handleSliderChange"
                />
                <div>Обкладинка з відео на {{ selectedTimePreview }}</div>
            </div>
            <b-form-checkbox
                v-if="!failedGeneratePreview"
                v-model="isCustomPreview"
                name="isCustomPreview"
                size="lg"
                :unchecked-value="false"
                :disabled="failedGeneratePreview"
            >
                Завантажити іншу обкладинку
            </b-form-checkbox>
            <div v-if="failedGeneratePreview" class="failed-preview">
                <div>
                    На жаль, ми не змогли автоматично згенерувати обкладинку до вашого відео.
                </div>
                <div>
                    Завантажте, будь ласка, свою. Без обкладинки відправка відео неможлива.
                </div>
            </div>
            <FileUploader
                v-if="isCustomPreview"
                :form="customPreviewForm"
                style="margin: 0 auto"
            />
            <InputGroup
                label="Назва відео"
                placeholder="Додайте назву відео для студентів"
                v-model="form.name"
                size="lg"
                class=""
                :is-error="v$.name?.$error"
                :error-message="v$.name?.$errors[0]?.$message"
                @blur="v$.name.$touch()"
            />
            <InputGroup
                label="Опис відео"
                placeholder="Додайте опис відео для студентів"
                v-model="form.description"
                size="lg"
                class=""
            />
            <div class="d-flex justify-content-end">
                <b-button
                    variant="primary"
                    size="md"
                    :disabled="!isVideo && !isLoading || (failedGeneratePreview && !customPreviewForm.filePreview)"
                    pill
                    class="mt-3"
                    @click="handleSend"
                >
                    Завантажити
                </b-button>
            </div>
        </div>
        <div v-if="isLoading" class="loading-backdrop"></div>
    </b-modal>
</template>
<style lang="scss" scoped>
.image-uploader__preview--image {
    width: 100%;
}

.wait-spinner {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: calc(50% - 1.5rem);
    left: calc(50% - 1.5rem);
    z-index: 1000;
}

.preview-time-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    margin: 24px 0;

    &:hover {
        opacity: 1;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: var(--green, #1db954);
        cursor: pointer;
    }

    &::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: var(--green, #1db954);
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.4;
    }
}

.video-uploader {
    max-width: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 20px;
    border: 2px dashed var(--stroke, #ebebeb);
    background: var(--white, #fff);
    padding: 20px 50px;
    margin: 0 auto;

    .video-uploader__title,
    .video-uploader__subtitle,
    i {
        color: var(--dark, #1e1e1e);
        text-align: center;
        opacity: 0.4;
        font-style: normal;
    }

    .video-uploader__title {
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
    }

    .video-uploader__subtitle {
        font-size: 12px;
        font-weight: 300;
        line-height: 140%;
    }
}

.video-uploader__preview {
    position: relative;
    //width: fit-content;
    background-color: #f7f7f8;
    overflow: hidden;
    border-radius: 20px;
    width: 100%;
    aspect-ratio: 16 / 9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-uploader__preview--image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.video-uploader__preview--delete {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: var(--stroke, #ebebeb);
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    i {
        font-size: 18px;
        color: var(--dark, #1e1e1e);
    }

    &:hover {
        background: var(--dark, #1e1e1e);

        i {
            color: var(--stroke, #ebebeb);
        }
    }
}

.loading-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.25;
    top: 0;
    left: 0;
    z-index: 999;
}
.failed-preview {
    font-family: e-Ukraine;
    font-size: 12px;
    text-align: center;
    color: var(--dark, #1e1e1e);
    padding: 8px 16px;
}
</style>
