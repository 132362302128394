<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { computed, ref } from "vue";
import CustomFieldModal from "@/Components/modals/CustomFieldModal.vue";
import CustomFormField from "@/Components/form/CustomFormField.vue";
import PaymentMethodField from "@/Components/form/PaymentMethodField.vue";
import PaymentMethodModal from "@/Components/modals/PaymentMethodModal.vue";
import { nextTick, watch } from "vue";
import { useRoleChecker } from "@/composables/useRoleChecker.js";

const customFieldModal = ref(false);
const paymentMethodsModal = ref(false);

const tempPaymentMethod = ref(null);

const props = defineProps({
    form: Object,
});

const pageData = usePage();
const { hasReadOnlyAccess } = useRoleChecker();

const userRoles = pageData?.props?.auth?.roles || [];
const isReadOnly = hasReadOnlyAccess(userRoles);

function saveTempCustomFields(tempRegisterFields) {
    //props.form.workShop.registerFields = props.form.workShop.registerFields.concat(tempRegisterFields)
    props.form.workShop.registerFields = [
        ...new Set([
            ...props.form.workShop.registerFields,
            ...tempRegisterFields,
        ]),
    ];
    customFieldModal.value = false;
}

const onCustomFieldRemove = (id) => {
    const index = props.form.workShop.registerFields.findIndex(
        (el) => el.id === id
    );
    if (index >= 0) {
        props.form.workShop.registerFields.splice(index, 1);
    }
};
const onPaymentMethodsRemove = (id) => {
    const index = props.form.workShop.paymentFields.findIndex(
        (el) => el.id === id
    );
    if (index >= 0) {
        props.form.workShop.paymentFields.splice(index, 1);
    }
};
const onPaymentMethodsEdit = (field) => {
    tempPaymentMethod.value = field;
    paymentMethodsModal.value = true;
};
const onPaymentMethodsAdd = () => {
    tempPaymentMethod.value = {
        id: new Date().valueOf(),
        name: "",
        value: "",
    };
    paymentMethodsModal.value = true;
};

const onSubmit = () => {
    emit("save");
};

const emit = defineEmits(["workShop-change", "save"]);
function savePaymentMethods(paymentMethod) {
    const index = props.form.workShop.paymentFields.findIndex(
        (el) => el.id === paymentMethod.id
    );
    if (index >= 0) props.form.workShop.paymentFields[index] = paymentMethod;
    else props.form.workShop.paymentFields.push(paymentMethod);
    tempPaymentMethod.value = null;
    paymentMethodsModal.value = false;
}

const registrationLimitInput = computed({
    get() {
        return props.form.workShop.config.registration_limit === 0 ||
            props.form.workShop.config.registration_limit === "0"
            ? ""
            : props.form.workShop.config.registration_limit;
    },
    set(value) {
        props.form.workShop.config.registration_limit = value
            ? parseInt(value)
            : 0;
    },
});

const workshopPrice = computed({
    get() {
        return props.form.workShop.config.price === 0 ||
            props.form.workShop.config.price === "0"
            ? ""
            : props.form.workShop.config.price;
    },
    set(value) {
        props.form.workShop.config.price = value ? parseFloat(value) : 0;
    },
});
</script>

<template>
    <b-container fluid class="tab-form">
        <b-row>
            <b-col cols="5">
                <b-card no-body class="shadow-none mt-3">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-4"
                    >
                        <div class="fs mb-3 card__title">
                            Поля для реєстрації
                            <i
                                id="registration-fields-tooltip"
                                class="bi bi-info-circle"
                            ></i>
                        </div>
                        <b-tooltip
                            class="text-left"
                            target="registration-fields-tooltip"
                            custom-class="custom-tooltip"
                            offset="5"
                            triggers="hover"
                            :no-fade="true"
                            :delay="{ show: 50, hide: 50 }"
                        >
                            Поля, які відвідувачі будуть заповнювати під час
                            реєстрації. Окрім базових, ви можете додати власні
                            поля, щоб адаптувати реєстрацію під ваші потреби.
                        </b-tooltip>
                        <div class="p-4 bg-white rounded-3 w-100">
                            <div
                                class="d-flex align-items-center"
                                v-for="field in props.form.workShop
                                    .registerFields"
                            >
                                <CustomFormField
                                    :form-field="field"
                                    @remove-by-id="onCustomFieldRemove"
                                />
                            </div>
                            <button
                                class="fs-6 text-primary py-3 card__title cursor-pointer"
                                :disabled="isReadOnly"
                                @click="customFieldModal = !customFieldModal"
                            >
                                Додати поля
                            </button>
                        </div>
                    </b-card-body>
                </b-card>

                <b-card no-body class="shadow-none mt-4">
                    <b-card-body
                        class="d-flex flex-column justify-content-between align-items-start bg-light p-3"
                    >
                        <b-form-group id="input-group-3" clex-colulass="my-4">
                            <b-form-checkbox
                                v-model="props.form.workShop.config.is_paid"
                                :disabled="isReadOnly"
                                :unchecked-value="0"
                            >
                                Зробити майстерклас платним
                                <i
                                    id="make-paid-workshop"
                                    class="bi bi-info-circle"
                                ></i>
                            </b-form-checkbox>
                            <b-tooltip
                                class="text-left"
                                target="make-paid-workshop"
                                custom-class="custom-tooltip"
                                offset="5"
                                triggers="hover"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Активуйте цю опцію, якщо хочете, щоб майстерклас
                                був платним
                            </b-tooltip>
                        </b-form-group>
                        <div
                            v-if="props.form.workShop.config.is_paid"
                            class="w-100"
                        >
                            <div class="fs my-3 card__title">
                                Вартість майстеркласу
                                <i
                                    id="workshop-price"
                                    class="bi bi-info-circle"
                                ></i>
                            </div>
                            <b-tooltip
                                class="text-left"
                                target="workshop-price"
                                custom-class="custom-tooltip"
                                offset="5"
                                triggers="hover"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Цю ціну будуть сплачувати учасники, щоб
                                потрапити на трансляцію.
                            </b-tooltip>
                            <div class="p-3 bg-white rounded-3 w-100">
                                <b-form-input
                                    v-model="workshopPrice"
                                    placeholder="Вартість (грн)"
                                    :disabled="isReadOnly"
                                ></b-form-input>
                            </div>
                            <div class="fs my-3 card__title">
                                Реквізити
                                <i
                                    id="payment-fields"
                                    class="bi bi-info-circle"
                                ></i>
                            </div>
                            <b-tooltip
                                class="text-left"
                                target="payment-fields"
                                custom-class="custom-tooltip"
                                offset="5"
                                triggers="hover"
                                :no-fade="true"
                                :delay="{ show: 50, hide: 50 }"
                            >
                                Учасники отримають реквізити для оплати під час
                                реєстрації.
                            </b-tooltip>
                            <div
                                v-for="paymentMethod in props.form.workShop
                                    .paymentFields"
                            >
                                <PaymentMethodField
                                    :form-field="paymentMethod"
                                    @remove-by-id="onPaymentMethodsRemove"
                                    @edit="onPaymentMethodsEdit"
                                />
                            </div>
                            <button
                                class="fs-6 text-primary py-3 card__title cursor-pointer"
                                :disabled="isReadOnly"
                                @click="onPaymentMethodsAdd"
                            >
                                Додати реквізити
                            </button>
                        </div>
                    </b-card-body>
                </b-card>

                <!--        <b-card no-body class="shadow-none mt-4">-->
                <!--          <b-card-body class="d-flex flex-column justify-content-between align-items-start bg-light p-3">-->
                <!--            <div class="fs mb-2 card__title">Ліміт реєстрацій <i id="registration-limit-tooltip" class="bi bi-info-circle"></i></div>-->
                <!--            <b-tooltip class="text-left" target="registration-limit-tooltip" custom-class="custom-tooltip" offset="5" triggers="hover" :no-fade="true" :delay="{ show: 50, hide: 50 }">-->
                <!--              Максимальна кількість людей, що зможе увійти в кімнату майстеркласу.-->
                <!--            </b-tooltip>-->
                <!--            <b-input-group class="workShop-input-group">-->
                <!--              <b-form-input class="workShop-form-input" type="number" step="1" v-model="registrationLimitInput" :disabled="isReadOnly" placeholder="Ліміт реєстрацій не встановлено"></b-form-input>-->
                <!--            </b-input-group>-->
                <!--          </b-card-body>-->
                <!--        </b-card>-->

                <b-button
                    variant="primary"
                    size="md"
                    pill
                    class="mt-3"
                    :disabled="form.processing || isReadOnly"
                    @click="onSubmit"
                >
                    Зберегти
                </b-button>
            </b-col>
        </b-row>
    </b-container>

    <CustomFieldModal
        v-if="customFieldModal"
        v-model="customFieldModal"
        @on-save="saveTempCustomFields"
    />
    <PaymentMethodModal
        v-model="paymentMethodsModal"
        :form-fields="tempPaymentMethod"
        @on-save="savePaymentMethods"
    />
</template>

<style scoped></style>
