<script setup>
import { Head, Link, useForm } from "@inertiajs/vue3";
import InputGroup from "@/Components/InputGroup.vue";
import LoginLayout from "@/Layouts/LoginLayout.vue";
import RegisterFormWrapper from "@/Pages/Auth/Partials/RegisterFormWrapper.vue";
import { computed, onMounted, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers, minLength } from "@vuelidate/validators";
import { FormWizard, WizardStep, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

defineOptions({ layout: LoginLayout });

const props = defineProps({
    baseUrl: String,
});

const form = useForm({
    name: "",
    email: "",
    password: "",
    domain_name: "",
    // password_confirmation: '',
});

const containsUpperCase = (value) => /[A-ZА-ЯЁ]/.test(value);
const containsLowerCase = (value) => /[a-zа-яё]/.test(value);

const containsDigit = (value) => /\d/.test(value);

const containsSpecialCharacters = (value) =>
    /[!@#$%^&*(),.?":{}|<>]/.test(value);

const containsNonCyrillic = (value) => !/[а-яА-ЯЁё]/u.test(value);

const lettersValidator = helpers.withMessage(
    "Пароль повинен містити великі та маленькі літери",
    (value) => {
        if (!value) return true;
        return (
            containsUpperCase(value) &&
            containsLowerCase(value) &&
            containsNonCyrillic(value)
        );
    }
);

const digitValidator = helpers.withMessage(
    "Пароль повинен містити хоча б одну цифру",
    (value) => {
        if (!value) return true;
        return containsDigit(value);
    }
);

const nonCyrillicValidator = helpers.withMessage(
    "Домен не може містити кириличних символів.",
    (value) => {
        if (!value) return true;
        return containsNonCyrillic(value);
    }
);

const hasSpecialCharacters = helpers.withMessage(
    "Поле повинно містити хоча б один спеціальний символ.",
    (value) => {
        if (!value) return true;
        return containsSpecialCharacters(value);
    }
);

const rules = {
    name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
    },
    email: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        email: helpers.withMessage("Введіть дійсну електронну адресу", email),
    },
    password: {
        required: helpers.withMessage(
            "Це поле є обов'язковим",
            (value) => !!value
        ),
        minLength: helpers.withMessage("Не менше 8 символів", minLength(8)),
        lettersValidator,
        digitValidator,
        hasSpecialCharacters,
    },
    domain_name: {
        required: helpers.withMessage("Це поле є обов'язковим", required),
        nonCyrillicValidator,
    },
};

const formWizard = ref(null);
const currentStep = ref(0);
const stepLength = ref(3);

const agreeRules = ref(false);

const v$ = useVuelidate(rules, form, { $lazy: true });

const errorIndexes = {
    email: 0,
    name: 1,
    password: 2,
    domain_name: 3,
};

const checkErrorIndex = (index) => {
    const key = Object.entries(errorIndexes).find(
        ([, value]) => value === index
    )?.[0];
    return key && Object.keys(form.errors).includes(key);
};

const handleNextButtonClick = async () => {
    if (currentStep.value === stepLength.value) {
        return;
    }

    if (currentStep.value === 0) {
        const data = { column: "email", text: form.email };
        const isAvailable = !(await verifyAvailability(data));

        form.clearErrors("email");
        if (!isAvailable) {
            form.setError("email", "Ця електронна пошта вже зайнята!");
            return;
        }
    }

    if (
        (currentStep.value !== 0) & (form.email !== "") &&
        form.domain_name === ""
    ) {
        form.domain_name = form.email.split("@")[0];
    }

    if (validateStep(currentStep.value)) {
        currentStep.value++;
        formWizard.value?.nextTab();
    }
};

const handlePrevButtonClick = () => {
    if (currentStep.value <= 0) {
        return;
    }

    currentStep.value--;
    formWizard.value?.prevTab();
};
const handleNavigateToTabClick = (index) => {
    if (index > formWizard.value?.maxStep) return;

    currentStep.value = index;
    formWizard.value?.navigateToTab(index);
};

const validateStep = (index) => {
    const key = Object.keys(errorIndexes).find(
        (key) => errorIndexes[key] === index
    );
    v$.value[key].$touch();
    return !v$.value[key].$error;
};

const onSubmit = () => {
    form.clearErrors("domain_name");
    verifyAvailability({ column: "domain_name", text: form.domain_name }).then(
        (isAvailable) => {
            if (!isAvailable) {
                form.post(route("register"), {
                    onFinish: () => form.reset("password"),
                });
            } else {
                form.setError("domain_name", "Це доменне ім'я вже зайняте!");
            }
        }
    );
};

const onReset = () => {
    form.reset();
};

const isVeryfy = ref(false);
const verifyAvailability = (data) => {
    isVeryfy.value = true;
    return axios
        .post("/verify-register-availability", data)
        .then((response) => {
            isVeryfy.value = false;
            return response.data.exists;
        })
        .catch((error) => {
            console.error(error);
            isVeryfy.value = false;
            return false;
        });
};
</script>

<template>
    <RegisterFormWrapper>
        <template #left>
            <div
            class="d-flex flex-column align-self-stretch justify-content-center align-items-center"
        >
            <Head title="Реєстрація на платформі" />

            <div class="form-login-header text-center">
                <h1 class="text-md login-title">Зареєструватись</h1>
                <div class="login-subtitle w-75 mx-auto">
                    Створюйте свої навчальні продукти за кілька кліків. Курс,
                    вебінар, менторство — все на одній платформі.
                </div>
            </div>

            <FormWizard
                ref="formWizard"
                disable-back
                hide-buttons
                step-size="xs"
                @reset="onReset"
                class="login-form mt-4"
                color="#1DB954"
            >
                <template #step="props">
                    <WizardStep
                        :tab="props.tab"
                        :transition="props.transition"
                        :index="props.index"
                        :class="{
                            'wizard-step__error': checkErrorIndex(props.index),
                        }"
                        @click="handleNavigateToTabClick(props.index)"
                    >
                    </WizardStep>
                </template>
                <TabContent title="Пошта" icon="ph-at-bold">
                    <InputGroup
                        v-model="form.email"
                        label="Email"
                        placeholder="Введіть email"
                        :error-message="
                            form.errors.email || v$.email?.$errors[0]?.$message
                        "
                        type="email"
                        required
                        autofocus
                        autocomplete="username"
                        :is-loading="isVeryfy"
                        :is-error="!!form.errors.email || v$.email.$error"
                        @blur="v$.email.$touch()"
                    />
                </TabContent>
                <TabContent title="Ім'я" icon="ph-user-bold">
                    <InputGroup
                        v-model="form.name"
                        label="Ваше ім'я"
                        placeholder="Введіть ім'я"
                        :error-message="
                            form.errors.name || v$.name?.$errors[0]?.$message
                        "
                        type="text"
                        required
                        autofocus
                        autocomplete="name"
                        :is-error="!!form.errors.name || v$.name.$error"
                        @blur="v$.name.$touch()"
                    />
                </TabContent>
                <TabContent title="Пароль" icon="ph-lock-bold">
                    <InputGroup
                        v-model="form.password"
                        label="Пароль"
                        placeholder="Введіть пароль"
                        :error-message="
                            form.errors.password ||
                            v$.password?.$errors.find(
                                (error) => error['$validator'] === 'required'
                            )?.$message
                        "
                        type="password"
                        required
                        autofocus
                        :is-error="!!form.errors.password || v$.password.$error"
                        @blur="v$.password.$touch()"
                    />
                    <ul class="list-inline password-recomendations-list">
                        <li
                            class="list-inline-item"
                            :class="{
                                invalid: v$.password?.$errors.some(
                                    (error) =>
                                        error['$validator'] === 'minLength'
                                ),
                            }"
                        >
                            Не менше 8 символів
                        </li>
                        <li
                            class="list-inline-item"
                            :class="{
                                invalid: v$.password?.$errors.some(
                                    (error) =>
                                        error['$validator'] ===
                                        'lettersValidator'
                                ),
                            }"
                        >
                            Маленькі та великі латинські літери
                        </li>
                        <li
                            class="list-inline-item"
                            :class="{
                                invalid: v$.password?.$errors.some(
                                    (error) =>
                                        error['$validator'] === 'digitValidator'
                                ),
                            }"
                        >
                            Цифри
                        </li>
                        <li
                            class="list-inline-item"
                            :class="{
                                invalid: v$.password?.$errors.some(
                                    (error) =>
                                        error['$validator'] ===
                                        'hasSpecialCharacters'
                                ),
                            }"
                        >
                            Спеціальний символ
                        </li>
                    </ul>
                </TabContent>
                <TabContent title="Домен" icon="ph-book-open-bold">
                    <div class="d-flex align-items-baseline gap-2">
                        <InputGroup
                            v-model="form.domain_name"
                            label="Ваше доменне ім'я"
                            placeholder="Введіть доменне ім'я"
                            :error-message="
                                form.errors.domain_name ||
                                v$.domain_name?.$errors[0]?.$message
                            "
                            type="text"
                            required
                            autofocus
                            autocomplete="domain"
                            :is-error="
                                !!form.errors.domain_name ||
                                v$.domain_name.$error
                            "
                            @blur="v$.domain_name.$touch()"
                        />
                        <div class="mt-4">
                            {{ baseUrl.replace("https://", ".") }}
                        </div>
                    </div>
                    <div
                        class="login-bottom-text mt-4 d-flex align-items-center"
                    >
                        <b-form-checkbox
                            v-model="agreeRules"
                            name="agreeRules"
                            size="lg"
                            :unchecked-value="false"
                            class="agree-rules__checkbox"
                        >
                        </b-form-checkbox>
                        <div style="text-align: left; margin-left: 10px">
                            Натискаючи на «Зареєструватись», ви погоджуєтесь з
                            нашими
                            <a
                                href="https://www.bestcleverslms.com/pravyla-vykorystannia/"
                                target="_blank"
                            >
                                Умовами використання</a
                            >,
                            <a
                                href="https://www.bestcleverslms.com/polityka-konfidentsiynosti/"
                                target="_blank"
                                >Політикою конфіденційності</a
                            >
                            та
                            <a
                                href="https://www.bestcleverslms.com/oferta/"
                                target="_blank"
                                >Публічним договором оферти</a
                            >
                        </div>
                    </div>
                </TabContent>
                <div class="d-flex justify-content-between mt-4">
                    <div v-if="currentStep === 0"></div>
                    <b-button
                        v-if="currentStep !== 0"
                        type="button"
                        size="md"
                        variant="outline-primary"
                        class="d-flex gap-1"
                        pill
                        @click="handlePrevButtonClick"
                    >
                        <i class="ri-arrow-left-line"></i> Повернутися
                    </b-button>
                    <b-button
                        v-if="currentStep !== stepLength"
                        type="button"
                        size="md"
                        variant="primary"
                        class="d-flex gap-1 float-right"
                        pill
                        @click="handleNextButtonClick"
                    >
                        Далі <i class="ri-arrow-right-line"></i>
                    </b-button>
                    <b-button
                        v-if="currentStep === stepLength"
                        type="button"
                        size="md"
                        variant="primary"
                        :disabled="!agreeRules || form.processing"
                        pill
                        @click="onSubmit"
                    >
                    Зареєструватись
                    </b-button>
                </div>
            </FormWizard>
        </div>
       </template>
       <template #right>
        <div class="login-right-overlay"></div>
       </template>
    </RegisterFormWrapper>
</template>

<style lang="scss">
.login-bottom-text {
    .form-check {
        padding: 0;
        .agree-rules__checkbox {
            margin: 0;
        }
    }
}

/* Змінюємо колір фону прогресбару для неактивних кроків */
.wizard-progress-with-circle {
    background-color: #f3f2ee;
}

/* Змінюємо колір фону кружечків (індикаторів) для неактивних кроків */
.wizard-progressbar-step:not(.wizard-step-active) .wizard-progressbar-circle {
    background-color: grey !important;
}
.wizard-step__error {
    .wizard-icon-circle {
        border: 2px solid #f24822 !important;
    }
}
.password-recomendations-list {
    margin-top: 10px;
    color: #1e1e1e;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;

    .list-inline-item {
        margin: 0 15px 0 0;
        padding: 0 0 0 10px;
        position: relative;

        &:before {
            display: inline-block;
            content: "";
            position: absolute;
            left: 0;
            top: 3px;
            width: 5px;
            height: 5px;
            background-color: var(--green, #1db954);
            border-radius: 50%;
        }

        &:not(:last-child) {
            margin-right: 1rem;
        }
        &.invalid {
            color: #f24822;
            &:before {
                background-color: var(--green, #f24822);
            }
        }
    }
}
</style>
