<script setup>
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref, watch } from "vue";
import { router, usePage } from "@inertiajs/vue3";
import downgrade from "@/../images/billing/downgrade.png";
import upgrade from "@/../images/billing/upgrade.png";
import {differenceInDays, parseISO} from "date-fns";
import { useSupportChatStore } from "@/stores/supportChatStore.js";

const props = defineProps({
    plan: {
        type: Object,
        required: true,
    },
    collapse: {
        type: Boolean,
        default: true,
    },
    isActive: {
        type: Boolean,
        default: false,
    },
    courseExchange: {
        type: Number,
    },
    billingPeriod: {
        type: Boolean,
        default: false,
    },
    currentPlan: {
        type: Boolean,
        default: null
    }
});

const emit = defineEmits(["handle-collapse"]);

const supportChatStore = useSupportChatStore();

const { t } = useI18n();

const pageData = usePage();

const hasTrial = computed(
    () => pageData?.props?.auth?.trial_status === "NOT_STARTED"
);

const handleBuyPlan = () => {
    //console.log("handleBuyPlan", props.plan.id, { period: props.billingPeriod ? 12 : 1 })
    router.visit(route("billing.subscribe", { tariffId: props.plan.id }), {
        method: "post",
        data: { period: props.billingPeriod ? 12 : 1, isBuyWithTrial: isBuyWithTrial.value },
        preserveState: true,
        preserveScroll: true,
        forceFormData: true,
        onSuccess: (response) => {
            //TODO: зробити редираект на платіжку
            console.log("billing.subscribe", response);
        },
    });
};

const yearPrice = computed(() => Math.ceil(props.plan.price * 12));
const yearDiscountPrice = computed(() =>
    Math.ceil(
        props.plan.price * 12 -
            props.plan.price * 12 * (props.plan.discount12 / 100)
    )
);

const yearPriceUa = computed(() =>
    Math.ceil(yearPrice.value * props.courseExchange)
);
const yearDiscountPriceUa = computed(() =>
    Math.ceil(yearDiscountPrice.value * props.courseExchange)
);

const countFormatterTags = (count) => {
    return count && count === -1 ? "необмежена к-ть" : count;
};
const countFormatter = (count) => {
    return count && count === -1 ? "Необмежено" : count;
};

const agreeRules = ref(false);
const isBuyWithTrial = ref(false);

const agreeRulesModal = ref(false);

watch(
    () => agreeRulesModal.value,
    (newValue) => {
        if (!newValue) agreeRules.value = false;
    },
    { deep: true }
);

const isDowngrade = computed(() => props?.currentPlan?.plan?.price && props?.plan?.price && (parseFloat(props.currentPlan.plan.price) > parseFloat(props.plan.price)))
const isUpgrade = computed(() => props?.currentPlan?.plan?.price && props?.plan?.price && (parseFloat(props.currentPlan.plan.price) < parseFloat(props.plan.price) && parseFloat(props.currentPlan.plan.price) !== 0))

const refund = computed(() => props?.currentPlan?.plan?.refund ? parseFloat(props.currentPlan.plan.refund.toFixed(2)) : null)
const refundUA = computed(() => refund.value && props.courseExchange ? Math.ceil(refund.value * props.courseExchange) : null)

const daysLeft = computed(() => props?.currentPlan?.plan?.next_payment ? differenceInDays(parseISO(props.currentPlan.plan.next_payment), new Date()) : '')
</script>

<template>
    <div
        :class="[
            'plan-info__card',
            {
                'individual-plan': plan.isIndividual,
                'is-active': isActive,
                'is-collapse': collapse,
            },
        ]"
    >
        <div class="plan-info__card--header">
            <div class="plan-title">
                {{ plan.name }}
            </div>
            <div v-if="!billingPeriod && !plan.isIndividual" class="plan-price">
                <div>
                    <div>{{ plan.price + " $" }}</div>
                    <div class="price-ua">
                        ({{ Math.ceil(plan.price * courseExchange) + " грн" }})
                    </div>
                </div>
            </div>
            <div v-if="billingPeriod && !plan.isIndividual" class="plan-price">
                <del>
                    <div>{{ yearPrice + " $" }}</div>
                    <div class="price-ua">({{ yearPriceUa + " грн" }})</div>
                    <div class="discount-amount">
                        -{{ plan.discount12 + "%" }}
                    </div>
                </del>
                <div>
                    <div>{{ yearDiscountPrice + " $" }}</div>
                    <div class="price-ua">
                        ({{ yearDiscountPriceUa + " грн" }})
                    </div>
                </div>
            </div>
            <div v-if="plan.isIndividual" class="plan-price">
                <div>
                    <div>Поговорімо</div>
                    <div class="price-ua">
                        &nbsp;
                    </div>
                </div>

            </div>
        </div>
        <div
            v-if="!plan.isIndividual && !isActive"
            class="plan-info__card--btn"
        >
            <b-button
                variant="primary"
                size="lg"
                pill
                class="btn-solid-primary"
                @click="agreeRulesModal = true ; isBuyWithTrial = false"
            >
                Придбати
<!--                {{ hasTrial ? "Спробувати безкоштовно" : "Придбати" }}-->
            </b-button>

            <b-button v-if="hasTrial"
                variant="primary"
                size="lg"
                pill
                class="btn-outline btn-outline-primary try-trial"
                @click="agreeRulesModal = true; isBuyWithTrial = true"
            >
                Спробувати 14 днів безкоштовно
            </b-button>
<!--            <span>протягом 14 днів</span>-->
            <b-modal
                v-if="agreeRulesModal"
                v-model="agreeRulesModal"
                id="handle-buy-plan"
                centered
                hide-footer
                hide-header
                no-close-on-backdrop
                no-close-on-esc
            >
                <div class="p-4 d-flex flex-column justify-content-center align-items-center">
                    <div v-if="isDowngrade || isUpgrade" class="payment-change">
                        <img v-if="isDowngrade" :src="downgrade" alt="downgrade" />
                        <img v-if="isUpgrade" :src="upgrade" alt="upgrade" />
                        <div class="payment-change__title">
                            {{ isUpgrade ? "Ви переходите на покращений план" : "" }}
                            {{ isDowngrade ? "Ви переходите на план з меншими можливостями" : "" }}
                        </div>
                        <div class="payment-change__desc">
                            {{ isUpgrade ? "Ми подбаємо, щоб залишок за невикористаний період (" + refund + "$ " + refundUA + "грн.) повернувся на вашу картку." : "" }}
                            {{ isDowngrade ? "Зверніть увагу, що залишок за поточний план не повертається. До завершення поточного плану залишилося " + daysLeft + " днів." : "" }}
                        </div>
                    </div>
                    <div v-else class="payment-change">
                        <img :src="upgrade" alt="upgrade" />
                        <div class="payment-change__title">
                            Ви вибираєте тариф {{ plan.name }}
                        </div>
                    </div>
                    <div class="payment-agreement mt-2">
                        <b-form-checkbox
                            v-model="agreeRules"
                            name="agreeRules"
                            size="lg"
                            :unchecked-value="false"
                            class="agree-rules__checkbox"
                        >
                            Здійснюючи покупку, ви приймаєте умови
                            <b-link
                                class="text-decoration-underline"
                                href="https://www.bestcleverslms.com/pravyla-vykorystannia/"
                                target="_blank"
                            >угоди користувача</b-link
                            >, з якими можете ознайомитися в акаунті або на сайті.
                        </b-form-checkbox>
                    </div>
                    <div class="d-flex gap-2">
                        <b-button
                            class="mt-3 outline-primary"
                            variant="primary"
                            size="lg"
                            pill
                            @click="agreeRulesModal = false"
                        >
                            Назад
                        </b-button>
                        <b-button
                            v-if="isUpgrade || isDowngrade"
                            class="mt-3"
                            variant="primary"
                            size="lg"
                            pill
                            :disabled="!agreeRules"
                            @click="handleBuyPlan"
                        >
                            {{ isUpgrade ? "Покращити план" : "Все одно перейти" }}
                        </b-button>
                        <b-button
                            v-if="parseFloat(currentPlan.plan.price) === 0"
                            class="mt-3 float-end"
                            variant="primary"
                            size="lg"
                            pill
                            :disabled="!agreeRules"
                            @click="handleBuyPlan"
                        >
                            {{ isBuyWithTrial ? "Спробувати безкоштовно" : "Придбати" }}
                        </b-button>
                    </div>
                </div>
            </b-modal>
        </div>
        <div v-if="plan.isIndividual && !isActive" class="plan-info__card--btn">
            <b-button
                class="btn-outline btn-outline-orange"
                @click="supportChatStore.toggleSupportChat"
                pill
                size="lg"
            >
                Звʼязатися
            </b-button>
        </div>
        <div class="active-label" v-if="isActive">Активний план</div>
        <!--        <div class="user-agreement">-->
        <!--            Здійснюючи покупку, ви приймаєте умови <b-link class="text-decoration-underline" href="https://www.bestcleverslms.com/pravyla-vykorystannia/" target="_blank">угоди користувача</b-link>, з якими можете ознайомитися в акаунті або на сайті.-->
        <!--        </div>-->
        <div v-if="!plan.isIndividual" class="plan-info__card--detail">
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.description") }}:
                </div>
                <div class="card-detail-row__text">{{ plan.description }}</div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.disc") }}:
                </div>
                <div class="card-detail-row__text">{{ plan.disc }}</div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.student_count") }}:
                </div>
                <div class="card-detail-row__text">
                    {{ countFormatter(plan.student_count) }}
                </div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.manager_count") }}:
                </div>
                <div class="card-detail-row__text">
                    {{ countFormatter(plan.manager_count) }}
                </div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.viewer_count") }}:
                </div>
                <div class="card-detail-row__text">
                    {{ countFormatter(plan.viewer_count) }}
                </div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.support") }}:
                </div>
                <div class="card-detail-row__text">Чат</div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.course_count") }}:
                </div>
                <div class="card-detail-row__text">
                    {{ countFormatter(plan.course_count) }}
                </div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.mentor_program_count") }}:
                </div>
                <div class="card-detail-row__text">
                    {{ countFormatter(plan.mentor_program_count) }}
                </div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.workshop_count") }}:
                </div>
                <div class="card-detail-row__text">
                    {{ countFormatter(plan.workshop_count) }}
                </div>
            </div>
            <div class="plan-info__card--detail-row">
                <div class="card-detail-row__label">
                    {{ $t("billing.key_features") }}:
                </div>
                <div class="card-detail-row__text">Необмежено</div>
            </div>
        </div>
        <div v-else class="plan-info__card--detail">
            <p class="individual-plan__title">
                Усе, що у тарифі Професійний, а також
            </p>
            <p>
                Всі налаштування платформи здійснюються під ваші потреби разом
                із нашим спеціалістом.
            </p>
            <p>
                Показники та вартість пакету визначаються індивідуально залежно
                від вашого запиту.
            </p>
            <p>Надаємо персональну підтримку.</p>
            <p>Підходить для великого бізнесу.</p>
        </div>
        <div v-if="collapse" class="collapse-panel">
            <div v-if="plan.disc && !isActive" class="collapse-tags">
                {{ plan.disc }}
            </div>
            <div v-if="plan.manager_count && !isActive" class="collapse-tags">
                {{ countFormatterTags(plan.manager_count) }} менеджерів
            </div>
            <div v-if="plan.student_count && !isActive" class="collapse-tags">
                {{ countFormatterTags(plan.student_count) }} студентів
            </div>
            <div v-if="plan.viewer_count && !isActive" class="collapse-tags">
                до {{ countFormatterTags(plan.viewer_count) }} глядачів
            </div>
            <!--            <div v-if="plan.configFields && !isActive" class="collapse-tags">-->
            <!--                {{plan.configFields.support}}-->
            <!--            </div>-->
            <b-button
                v-if="isActive"
                variant="link"
                class="collapse-tags"
                size="sm"
                pill
                style="height: 32px"
                @click="emit('handle-collapse')"
            >
                Всі умови плану
            </b-button>
            <b-button
                v-if="!isActive"
                variant="primary"
                class="more-info"
                size="sm"
                pill
                style="height: 32px"
                @click="emit('handle-collapse')"
            >
                Всі переваги
            </b-button>
        </div>
    </div>
</template>

<style scoped lang="scss"></style>
